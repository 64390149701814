import { Injectable, Injector } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { plainToClass } from 'class-transformer';
import _, { clone } from 'lodash';
import * as moment from 'moment';
import { combineLatest, forkJoin, from, Observable, of } from 'rxjs';
import { catchError, delay, finalize, map, mergeMap, take, tap } from 'rxjs/operators';
import { QuoteDetail } from '../../../modules/back-office/models/quotedetailresponse-response';
import { AssetDetailResponse } from '../../../modules/change-product/models/asset-detail.response';
import { CodicePlico } from '../../../modules/common/order-entry/models/codice-plico';
import { D365Over75Data } from '../../../modules/common/order-entry/models/d365-over75-data';
import { DataEsecuzione } from '../../../modules/common/order-entry/models/data-esecuzione';
import { AgencyBranchForSubsidiary } from '../../../modules/switch-in/technical-changes/models/agency-branch-for-subsidiary';
import { AssetIntegrationIdData } from '../../../modules/switch-in/technical-changes/models/asset-integration-id';
import { AgencyEseData } from '../../../modules/switch-in/technical-changes/models/d365-ese-agency';
import {
    D365CaseCloseResponse,
    D365RetrieveUpsertCase,
    UpsertCaseReq,
} from '../../../modules/switch-in/technical-changes/models/d365-upsert-case';
import { D365WorkOrderCaseData } from '../../../modules/switch-in/technical-changes/models/d365-work-order-case-data';
import { D365WorkOrderLogisticData } from '../../../modules/switch-in/technical-changes/models/d365-work-order-logistic-data';
import { D365WorkOrderServiceData } from '../../../modules/switch-in/technical-changes/models/d365-work-order-service-data';
import { D365Incident } from '../../../modules/switch-in/technical-changes/models/formatted-incident';
import { setPassaggioRapido } from '../../../store/actions/order-entry.actions';
import {
    setAgentInfo,
    setContact,
    setCustomerInfo,
    setCustomerMastership,
    setLastUsedCustomerSegment,
    setVirtualAgentAgencyCurrentBranch,
} from '../../../store/actions/user.actions';
import { FlowType } from '../../../store/models/flow-type';
import { PowerConsumption, TipoEsecuzione } from '../../../store/models/order-entry-state';
import { Address } from '../../../store/models/order-entry-state_v2';
import { MastershipType } from '../../../store/models/user-state';
import { EglState } from '../../../store/reducers';
import { selectFullState } from '../../../store/selectors/common.selectors';
import {
    v2SelectCommunicationMethodAddress,
    v2SelectFirma,
    v2SelectFlowType,
} from '../../../store/selectors/order-entry-v2.selectors';
import {
    selectAgentInfo,
    selectCartSegment,
    selectChannelCode,
    selectContactLead,
    selectCurrentVirtualAgent,
} from '../../../store/selectors/user.selectors';
import { Regex } from '../../config/regex';
import { AptBillType } from '../../enums/apttus/apt-bill-type';
import { D365AccountMigrated } from '../../enums/d365/d365-account-migrated';
import { D365ChannelCode } from '../../enums/d365/d365-channel-code';
import { D365ConfirmationType } from '../../enums/d365/d365-confirmation-type';
import { D365CustomerSegment } from '../../enums/d365/d365-customer-segment';
import { D365OperationMode } from '../../enums/d365/d365-operation-mode';
import { D365SignatureType } from '../../enums/d365/d365-signature-type';
import { D365TemplateName } from '../../enums/d365/d365-template-name';
import { CustomerType } from '../../enums/shared/customer-type';
import { PrivacyType } from '../../enums/shared/privacy-question-type';
import {
    d365ResponseSugarHam,
    flagToBool,
    flagToBoolOrNull,
    getRandomHash,
    getTransactionId,
    singleLineString,
} from '../../functions/misc.functions';
import { d365AccountMigratedToMastershipType, flowTypeToD365OperationMode } from '../../functions/remap.functions';
import { getCompleteCivic, getIstatCode } from '../../functions/string-format.functions';
import { flowTypeUtil } from '../../functions/verifications.functions';
import { BaseApiResponse, StatusResponse } from '../../interfaces/base-api-response';
import { BaseD365Response } from '../../interfaces/base-d365-response';
import { D365PostMessage } from '../../models/app/d365-postmessage';
import { Oauth2AccessToken } from '../../models/d365/accessToken.response';
import { AgencyBranch } from '../../models/d365/agency-branch';
import { AgencyBranchSearchResponse } from '../../models/d365/agency-branch-response';
import { AgentSearchResponse } from '../../models/d365/agent-search-response';
import { Alert } from '../../models/d365/alert';
import { AppLocation } from '../../models/d365/app-location';
import { Appointment, AppointmentResponse } from '../../models/d365/appointment';
import { Ateco } from '../../models/d365/ateco';
import { CaringLightConfiguration, UserCaringLightConfiguration } from '../../models/d365/caring-light-configuration';
import {
    ChannelMarketConfiguration,
    ChannelMarketConfigurationResponse,
} from '../../models/d365/channel-market-configuration';
import { ChannelListSearchResponse } from '../../models/d365/channel-search-response';
import { StatusCheckType } from '../../models/d365/checkcall';
import { CreateLeadFromInterestsResponse } from '../../models/d365/create-lead-from-interest-response';
import { CreateRecordResponse } from '../../models/d365/create-record.response';
import { D365AssetRoleByAccounts } from '../../models/d365/d365-address-role-by-accountsinterface';
import { D365Account, D365AccountContactAddressRaw, D365Asset } from '../../models/d365/d365-asset.interface';
import { DataOwnershipChangeOutcomeRequest } from '../../models/d365/data-ownership-change-outcome.request';
import { DataOwnershipChangeOutcomeResponse } from '../../models/d365/data-ownership-change-outcome.response';
import { DataOwnershipChangeRequest } from '../../models/d365/data-ownership-change.request';
import { DataOwnershipChangeResponse } from '../../models/d365/data-ownership-change.response';
import { CheckTypeResponse } from '../../models/d365/get-check-type-response';
import { GetCampaignListResponse } from '../../models/d365/getcampaignlist-response';
import { UpsertCaseCaringReq } from '../../models/d365/incident.record';
import { KnowledgeMaterialListItem } from '../../models/d365/knowledge-material-list-response';
import { KnowledgeSearchCategory } from '../../models/d365/knowledge-search-category';
import { LeadDataForInterest } from '../../models/d365/lead-interest-data';
import { LeadTopic } from '../../models/d365/lead-topic';
import { LegalForm } from '../../models/d365/legal-form';
import { LookupFilter } from '../../models/d365/lookup-filter';
import { MigrationNewAccountStrategyResponse } from '../../models/d365/migration-new-account-strategy';
import { MultipleLookupObjectResponse } from '../../models/d365/multiple-lookup-object.response';
import { MuicipalityPodConfiguration, Municipality } from '../../models/d365/municipality-transfer-block';
import { NavigateToRequest } from '../../models/d365/navigate-to-request';
import { NavigateToResponse } from '../../models/d365/navigate-to-response';
import { NewsContent, NewsListItem } from '../../models/d365/news';
import { OmniChannelAppContext } from '../../models/d365/omni-channel-app-context';
import { OperativeModesByProfile } from '../../models/d365/operative-modes-by-profile';
import { PhonePrefix } from '../../models/d365/phone-prefix';
import { Preventivi, PreventiviResponse } from '../../models/d365/preventivi';
import { QuerySearchRequest } from '../../models/d365/query-search';
import { SecurityDeposit, SecurityDepositResponse } from '../../models/d365/security-deposit.response';
import { SelectFileResponse } from '../../models/d365/select-file-response';
import { SuggestSearchRequest, SuggestSearchResponse } from '../../models/d365/suggest-search';
import { UpsertPreventivoResponse } from '../../models/d365/upsert-preventivo';
import { VirtualAgencyListSearchResponse } from '../../models/d365/virtual-agency-search-response';
import { VoltageLevel } from '../../models/d365/voltage-level';
import { WidgetListItem } from '../../models/d365/widget';
import { WorkOrderD365Request } from '../../models/d365/work-order.model';
import { AgencyBranchForMonitoring, AgentInfo, VirtualAgent, VirtualAgentsForBoList } from '../../models/user/agent';
import { Contact } from '../../models/user/contact';
import { CustomerInfo } from '../../models/user/customer';
import { Lead } from '../../models/user/lead';
import { WorkOrderFlowCodes } from '../../models/work-order-flow-codes.enum';
import { mapFields } from '../../utility/functions/map-fields';
import { AppInsightsService } from '../shared/app-insights.service';
import { CreditCheckService } from '../shared/credit-check.service';
import { FeatureToggleService } from '../shared/feature-toggle.service';
import { LoadingService } from '../shared/loading.service';
import { LoggerService } from '../shared/logger.service';
import { PrivateConfigurationService } from '../shared/private-configuration.service';
import { CheckActiveSurveyRequest, CheckActiveSurveyResponse } from '../shared/survey.service';
import { CustomerDataChangeResponse } from './../../../modules/back-office/models/custome-data-change.response';
import { CustomerDataChangeRequest } from './../../../modules/back-office/models/customer-data-change.request';
import { BaseD365Service } from './base-d365.service';
import {
    AgencyESEInfo,
    D365AgencyESEInfo,
    D365ToAgencyESEFieldMap,
    getAgencyESEXmlQuery,
} from './fetch-xml-query/agency-branch-address';
import {
    D365ConsumerCreditWorkOrder,
    D365ToSupConsumerCreditWorkOrderFieldMap,
    getConsumerCreditWorkOrderXmlQuery,
    SupConsumerCreditWorkOrder,
} from './fetch-xml-query/consumer-credit-work-order';
import {
    D365DocumentaryCheckWorkOrder,
    D365ToSupDocumentaryCheckWorkOrderFieldMap,
    getDocumentaryCheckWorkOrderXmlQuery,
    SupDocumentaryCheckWorkOrder,
} from './fetch-xml-query/documentary-check-work-order';
import {
    D365CutOffDate,
    D365ToSupCutOffDateFieldMap,
    getCutOffDateXmlQuery,
    SupCutOffDate,
} from './fetch-xml-query/fetch-xml-cut-off-date';
import { FetchXmlParams, FetchXmlQuery, getFetchXmlQuery } from './fetch-xml-query/fetch-xml-manager';
import {
    D365SerialNumber,
    D365ToSupSerialNumberFieldMap,
    getSerialNumberXmlQuery,
    SupSerialNumber,
} from './fetch-xml-query/fetch-xml-serial-number';
import {
    AGENCY_ESE_COMPLEX,
    ASSET_INTEGRATION_ID_BY_ASSET_ID,
    ATECO_CODES_QUERY,
    CASE_DATA_BY_CASE_ID,
    CASE_DATA_BY_QUOTE,
    getAccountContactQuery,
    getD365AddressCodeQuery,
    getD365ResidentialAddressByAccountNumber,
    getLeadQuery,
    getPhoneCallQuery,
    GET_OVER75_RANGE,
    GET_WORK_ORDER_CASE,
    GET_WORK_ORDER_DATA,
    GET_WORK_ORDER_LOGISTIC,
    INCIDENTS_BY_CUSTOMER_CODE,
    LEAD_IDS_BY_CUSTOMER,
    SUBSIDIARY_BY_AGENCY_ID,
} from './fetch-xml-query/xml-queries';

@Injectable({
    providedIn: 'root',
})
export class D365Service extends BaseD365Service {
    constructor(
        protected logger: LoggerService,
        protected store: Store<EglState>,
        private appInsightsSrv: AppInsightsService,
        private ccSrv: CreditCheckService,
        protected configSrv: PrivateConfigurationService,
        private toggleService: FeatureToggleService,
        protected injector: Injector,
    ) {
        super(logger, store, configSrv, injector);
    }

    updateCurrentVirtualAgent(currentUserId: string, virtualAgentId: string): Promise<any> {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: { UserGuid: currentUserId, VirtualAgentGuid: virtualAgentId },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_setcurrentvirtualagent',
                    parameterTypes: {
                        UserGuid: { typeName: 'Edm.String', structuralProperty: 1 },
                        VirtualAgentGuid: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };

        return this.asyncPostMessage(message).toPromise();
    }

    updateRecord(entity: string, entityGuid: string, data: any, responseType?: string) {
        const message: D365PostMessage = {
            type: 'updaterecordrequest',
            content: {
                entity,
                guid: entityGuid,
                data,
                type: responseType || `${entity}updateresponse`,
            },
        };
        return this.asyncPostMessage<void>(message);
    }

    openCreationFormOnCrm(entity: string, formId: string) {
        const formOption = { entityName: entity, formId };
        const message: D365PostMessage = { type: 'xrmnavigation', content: JSON.stringify(formOption) };
        return this.asyncPostMessage(message).toPromise();
    }

    openViewFormOnCrm(entity: string, entityGuid: any, formId: string) {
        const formOption = { entityName: entity, formId, entityId: entityGuid };
        const message: D365PostMessage = { type: 'xrmnavigation', content: JSON.stringify(formOption) };
        return this.asyncPostMessage(message).toPromise();
    }

    openViewFormOnCrmWithParameters(
        entity: string,
        entityGuid: any,
        formId: string,
        parameters: any,
        openInNewWindow: boolean = false,
    ) {
        const content = {
            formOptions: { entityName: entity, formId, entityId: entityGuid, openInNewWindow: openInNewWindow },
            parameters,
        };
        const message: D365PostMessage = { type: 'xrmnavigationwithparameters', content: JSON.stringify(content) };
        return this.asyncPostMessage(message).toPromise();
    }

    getLead(userGuid: string, customerSegment?: number): Observable<Lead> {
        const start = new Date().getTime();

        return this.retrieveMultipleRecordsFetchAsync(getLeadQuery(userGuid)).pipe(
            map((responses) => (!!responses.length ? responses[0] : {})),
            map((response) => ({
                lead: {
                    leadid: response?.leadid,
                    firstname: response?.firstname,
                    lastname: response?.lastname,
                    telephone1: response?.telephone1,
                    mobilephone: response?.mobilephone,
                    emailaddress1: response?.emailaddress1,
                    egl_productdetails: response?.egl_productdetails,
                    egl_code: response?.egl_code,
                    egl_taxcode: response?.egl_taxcode,
                    egl_privacy1: response?.egl_privacy1,
                    egl_privacy2: response?.egl_privacy2,
                    egl_privacy3: response?.egl_privacy3,
                    parentcontactid_value: response?._parentcontactid_value,
                    parentaccountid_value: response?._parentaccountid_value,
                    egl_customercode: response?.egl_customercode,
                    egl_customeraccountcode: response?.egl_customeraccountcode,
                    telephone2: response?.telephone2,
                    address1_line1: response?.address1_line1,
                    address1_city: response?.address1_city,
                    address1_postalcode: response?.address1_postalcode,
                    address1_stateorprovince: response?.address1_stateorprovince,
                    egl_customersegmentcode: response?.egl_customersegmentcode,
                    address2_postalcode: response?.address2_postalcode,
                    address2_city: response?.address2_city,
                    address2_stateorprovince: response?.address2_stateorprovince,
                    address2_line1: response?.address2_line1,
                    egl_vatcode: response?.egl_vatcode,
                    campaignid_value: response?._campaignid_value,
                    egl_tag: response?.egl_tag,
                    egl_phonecall_interactionid: response?.egl_phonecall_interactionid,
                } as any,
                campaign: response?.campaign,
                mastership: response?.account?.egl_migration_mastercode,
            })),
            tap(({ mastership }) =>
                this.toggleService.isSwitchInE2EEnabled
                    ? this.store.dispatch(
                          setCustomerMastership({
                              customerMastership:
                                  d365AccountMigratedToMastershipType(mastership) ||
                                  (this.configSrv.get<boolean>('defaultSellabilityMatrixIsSiebelCustomer', false)
                                      ? MastershipType.D365
                                      : null),
                          }),
                      )
                    : null,
            ),
            tap(({ lead: { egl_tag } }) => {
                this.logger.warn(`Trovato tag base lista con valore ${egl_tag}`);
            }),
            map(
                ({
                    lead: {
                        telephone1,
                        telephone2,
                        address2_postalcode,
                        address2_city,
                        address2_line1,
                        address2_stateorprovince,
                        campaignId,
                        campaignName,
                        campaignCode,
                        campaignTypeCode,
                        egl_customersegmentcode,
                        ...lead
                    },
                    campaign: { campaignid, name, egl_code, egl_campaigntypecode },
                }) =>
                    ({
                        ...lead,
                        mobilephone: telephone1,
                        originalTelephone1: telephone1,
                        originalTelephone2: telephone2,
                        telephone1: telephone2,
                        telephone2: telephone2,
                        address1_postalcode: address2_postalcode,
                        address1_city: address2_city,
                        address1_line1: address2_line1,
                        address1_stateorprovince: address2_stateorprovince,
                        campaignId: campaignid || campaignId,
                        campaignName: name || campaignName,
                        campaignCode: egl_code || campaignCode,
                        campaignTypeCode: egl_campaigntypecode || campaignTypeCode,
                        egl_customersegmentcode: (egl_customersegmentcode || customerSegment) as D365CustomerSegment,
                    }) as Lead,
            ),
            tap(() => {
                const end = new Date().getTime();
                this.appInsightsSrv.logMetric(`get-lead`, (end - start) / 1000);
            }),
        );
    }

    getContact(userGuid: string): Promise<Contact> {
        const start = new Date().getTime();
        return this.getAccountInfo(userGuid)
            .pipe(
                map((customer) =>
                    Object.assign(customer || ({} as CustomerInfo), {
                        CustomerType: CustomerType.Account,
                    }),
                ),
                tap((customer) => {
                    this.store.dispatch(setCustomerInfo({ c: customer }));
                    this.ccSrv.mapD365CreditCheckData(
                        customer.CreditCheckOutcome,
                        customer.TaxCode || customer.VatCode,
                    );
                }),
                map((customer) =>
                    Object.assign(new Contact(), {
                        name: customer.Name,
                        firstname: customer.FirstName,
                        lastname: customer.LastName,
                        egl_taxcode: customer.TaxCode,
                        emailaddress1: customer.Email,
                        address1_line1: `${customer.Toponomastica || ''} ${customer.Address || ''} ${
                            customer.StreetNumber || ''
                        }`.trim(),
                        address1_stateorprovince: customer.Province,
                        address1_postalcode: customer.PostalCode,
                        egl_customercode: customer.CustomerCode,
                        address1_city: customer.City,
                        mobilephone: customer.Telephone1,
                        telephone1: customer.Telephone2,
                        telephone2: customer.Telephone2,
                        accountid: userGuid,
                        egl_customeraccountcode: customer.CustomerAccountCode,
                        contactid: customer.PrimaryContactId,
                        egl_customersegmentcode: customer.CustomerSegment,
                        egl_vatcode: customer.VatCode,
                        EglMigration: this.toggleService.isSwitchInE2EEnabled ? customer.EglMigration : null,
                    }),
                ),
                tap((contact) => {
                    if (this.toggleService.isSwitchInE2EEnabled) {
                        this.store.dispatch(
                            setCustomerMastership({
                                customerMastership: d365AccountMigratedToMastershipType(contact.EglMigration),
                            }),
                        );
                    }
                    this.store.dispatch(setContact({ c: contact, fcrm: true }));
                }),
                finalize(() => {
                    const end = new Date().getTime();
                    this.appInsightsSrv.logMetric(`get-contact`, (end - start) / 1000);
                }),
            )
            .toPromise();
    }

    getCodicePlico$(
        channel: string,
        signatureTypeCode: D365SignatureType,
        operationModeCode: D365OperationMode,
    ): Observable<CodicePlico> {
        const start = new Date().getTime();
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: {
                    Channel: channel,
                    SignatureTypeCode: signatureTypeCode,
                    OperativeModeCode: operationModeCode,
                },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_generatecontractcode',
                    parameterTypes: {
                        Channel: { typeName: 'Edm.String', structuralProperty: 1 },
                        SignatureTypeCode: { typeName: 'Edm.Int32', structuralProperty: 1 },
                        OperativeModeCode: { typeName: 'Edm.Int32', structuralProperty: 1 },
                    },
                },
            },
        };
        return this.asyncPostMessage<CodicePlico>(message).pipe(
            tap((resp) => {
                if (!resp?.ContractCode) {
                    const defaultErrorDescription = resp?.ErrorMessage || 'Numero plico non generato';
                    this.logger.error('Errore', defaultErrorDescription, JSON.stringify(resp), true);
                    throw new Error(resp?.ErrorMessage || defaultErrorDescription);
                }
            }),
            finalize(() => {
                const end = new Date().getTime();
                this.appInsightsSrv.logMetric(`get-codice-plico`, (end - start) / 1000);
            }),
        );
    }

    openCamera(responseType?: string) {
        if (!responseType) {
            responseType = 'takePicture';
        }

        const message: D365PostMessage = {
            type: 'opencamerarequest',
            content: {
                type: responseType || 'takePicture',
            },
        };
        this.asyncPostMessage(message);
    }

    topNavigationRequest(url: string) {
        const message = {
            type: 'topnavigationrequest',
            content: JSON.stringify(url),
        };
        return this.asyncPostMessage(message);
    }

    createOmniChannelTab(
        templateName: D365TemplateName,
        appContext: OmniChannelAppContext,
        isFocused: boolean = true,
    ): Observable<void> {
        const content = {
            templateName: templateName,
            appContext: appContext,
            isFocused: isFocused,
        };
        const message = {
            type: 'openOmniChannelTab',
            content: JSON.stringify(content),
        };
        return this.asyncPostMessage(message);
    }

    navigateToUnifiedView(customerCode: string) {
        const message = {
            type: 'navigatetounifiedview',
            content: customerCode,
        };
        return this.asyncPostMessage(message);
    }

    /**
     * @description Naviga alla pagina del work order al click del numero di work order utilizzando sales app bridge di D365
     */
    navigateToWorkOrder(workorderid: string) {
        const message = {
            type: 'navigateToWorkOrder',
            content: workorderid,
        };
        return this.asyncPostMessage(message);
    }

    navigateToAssetView(assetLineItemId: string) {
        const message = {
            type: 'navigatetoassetform',
            content: assetLineItemId,
        };
        return this.asyncPostMessage(message);
    }

    navigateToBillingPreferenceUnifiedView(billingPreferenceCode: string) {
        const message = {
            type: 'navigatetobillingpreferenceunifiedview',
            content: billingPreferenceCode,
        };
        return this.asyncPostMessage(message);
    }

    getVirtualAgentsBo(agcode: string): Observable<VirtualAgentsForBoList> {
        // const type = 'virtualagentsresponse' + getRandomHash();
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                outparameter: 'ResponseJson',
                request: { RequestVirtualAgentCode: agcode },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_getvirtualagentsforbo',
                    parameterTypes: {
                        RequestVirtualAgentCode: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };

        return this.asyncPostMessage<VirtualAgentsForBoList>(message);
    }

    //TODO Lorenzo - Inserisci un modello diverso da "any"
    getCampaignRecords(customerCode: string, billingPreference: string = null): Observable<any[]> {
        if (customerCode) {
            const message: D365PostMessage = {
                type: 'executeaction',
                content: {
                    request: {
                        CustomerCode: customerCode,
                        DiscountFlag: true,
                        BillingPreference: billingPreference,
                    },
                    requestmetadata: {
                        boundParameter: null,
                        operationType: 0,
                        operationName: 'egl_getcampaignlist',
                        parameterTypes: {
                            CustomerCode: { typeName: 'Edm.String', structuralProperty: 1 },
                            BillingPreference: { typeName: 'Edm.String', structuralProperty: 1 },
                            DiscountFlag: { typeName: 'Edm.Boolean', structuralProperty: 1 },
                        },
                    },
                },
            };

            return this.asyncPostMessage<GetCampaignListResponse>(message).pipe(
                mergeMap((res) => {
                    if (res.Result == '001' && res.Response) {
                        const records = JSON.parse(res.Response)?.BodyResponse?.Records;
                        return of(records);
                    }
                    return of([]);
                }),
            );
        } else return of([]);
    }

    getNewsList(): Promise<NewsListItem[]> {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: {},
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_news_retrievevalidnews',
                    parameterTypes: {},
                },
            },
        };
        return this.asyncPostMessage<NewsListItem[]>(message, 'ValidNewsList').toPromise();
    }

    getPreventivi(CustomerCode: string): Observable<Preventivi[]> {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: { CustomerCode },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_distributorquoterequest_getbycustomercode',
                    parameterTypes: {
                        CustomerCode: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };
        return this.asyncPostMessage<PreventiviResponse>(message).pipe(
            tap((res: PreventiviResponse) => {
                if (res?.Result !== 'OK') {
                    this.logger.error(null, this.genericApiError, res?.Result || this.genericApiError, true);
                }
            }),
            map((res) =>
                res?.DistributorQuoteRequestList
                    ? JSON.parse(res.DistributorQuoteRequestList)?.DistributorQuoteRequests
                    : [],
            ),
        );
    }

    getAppointments(OrderLineItemId: string): Observable<Appointment[]> {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: { OrderLineItemId },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_getappointmentinfo',
                    parameterTypes: {
                        OrderLineItemId: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };
        return this.asyncPostMessage<AppointmentResponse>(message).pipe(
            tap((res: AppointmentResponse) => {
                if (res?.Result !== 'OK') {
                    this.logger.error(null, this.genericApiError, res?.Result || this.genericApiError, true);
                }
            }),
            map((res) => (res?.AppointmentInfo ? JSON.parse(res.AppointmentInfo)?.Appointments : [])),
            map((appointments: Appointment[]) => {
                return appointments.map((app) => ({
                    ...app,
                    ScheduledStart: moment(app.ScheduledStart).format('DD/MM/YYYY hh:mm'),
                    ScheduledEnd: moment(app.ScheduledEnd).format('DD/MM/YYYY hh:mm'),
                }));
            }),
        );
    }

    editCustomerData({ address, billingAccount }: { address: Address; billingAccount: string }): Observable<void> {
        const request: CustomerDataChangeRequest = {
            CustomerDataChangeRequest: JSON.stringify({
                InfoLog: {
                    transactionId: getTransactionId(),
                },
                mustSendCommunication: false,
                channel: 'APP',
                mustUpdateCpq: false,
                billingPreferences: [
                    {
                        code: billingAccount,
                        billingAddressToponym: address?.toponym,
                        billingAddressStreet: address?.street,
                        billingAddressNumber: getCompleteCivic(address),
                        billingAddressZipCode: address?.cap,
                        billingAddressCity: address?.municipality,
                        billingAddressProvince: address?.shortProvince,
                        billingAddressCountry: address?.country,
                        billingAddressIstatCode: getIstatCode(address),
                    },
                ],
            }),
        };
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request,
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_customerdatachange_sendcustomerdatachange',
                    parameterTypes: {
                        CustomerDataChangeRequest: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };
        return this.asyncPostMessage<CustomerDataChangeResponse>(message).pipe(
            tap((res) => {
                if (res?.Result !== 'OK') {
                    throw new Error(res?.ErrorMessage || 'Error calling D365 editCustomerData');
                }
            }),
            map(() => null),
        );
    }

    getAvailableSurvey(request: CheckActiveSurveyRequest): Observable<CheckActiveSurveyResponse> {
        const type = 'activeSurvey' + getRandomHash();
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                type,
                request: request,
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_survey_retrievesurveyforentrypointanduser',
                    parameterTypes: {
                        EntryPoint: { typeName: 'Edm.String', structuralProperty: 1 },
                        VirtualAgentId: { typeName: 'Edm.String', structuralProperty: 1 },
                        CustomerTaxVatCode: { typeName: 'Edm.String', structuralProperty: 1 },
                        ProfileType: { typeName: 'Edm.Int32', structuralProperty: 1 },
                        CampaignId: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };

        return this.asyncPostMessage<CheckActiveSurveyResponse>(message);
    }

    getNewsContent(newsId: string): Promise<NewsContent> {
        const type = 'newsContent' + getRandomHash();
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: { NewsId: newsId },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_news_getbyid',
                    parameterTypes: {
                        NewsId: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };

        const endpoint = this.configSrv.get('d365.url');
        return this.asyncPostMessage<NewsContent>(message)
            .pipe(
                map(({ NewsDescription, ...res }) => ({
                    ...res,
                    NewsDescription: (NewsDescription || '').replace(
                        /src="\/api\/data/g,
                        'src="' + endpoint + '/api/data',
                    ),
                })),
            )
            .toPromise();
    }

    verifyTelephoneNumber(
        tSubject: string,
        tToPhoneNumber: string,
        tTemplate?: string,
        tToId?: string,
        tEntityName?: string,
        tSubstitutionArguments?: string,
    ): Promise<any> {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: {
                    Subject: tSubject,
                    ToPhoneNumber: tToPhoneNumber,
                    TemplateId: tTemplate,
                    ToId: tToId,
                    ToEntityName: tEntityName,
                    SubstitutionArguments: tSubstitutionArguments,
                },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_sms_createandsend',
                    parameterTypes: {
                        Subject: { typeName: 'Edm.String', structuralProperty: 1 },
                        ToPhoneNumber: { typeName: 'Edm.String', structuralProperty: 1 },
                        TemplateId: { typeName: 'Edm.String', structuralProperty: 1 },
                        ToId: { typeName: 'Edm.String', structuralProperty: 1 },
                        ToEntityName: { typeName: 'Edm.String', structuralProperty: 1 },
                        SubstitutionArguments: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };

        return this.asyncPostMessage(message).toPromise();
    }

    verifyEmailAddress(
        tSubject: string,
        tBody: string,
        tToEmail: string,
        tTemplate?: string,
        tToId?: string,
        tEntityName?: string,
        tSubstitutionArguments?: any,
    ): Promise<any> {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: {
                    Subject: tSubject,
                    Body: tBody,
                    ToEmail: tToEmail,
                    TemplateId: tTemplate,
                    ToId: tToId,
                    ToEntityName: tEntityName,
                    SubstitutionArguments: JSON.stringify(tSubstitutionArguments),
                },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_email_createandsend',
                    parameterTypes: {
                        Subject: { typeName: 'Edm.String', structuralProperty: 1 },
                        Body: { typeName: 'Edm.String', structuralProperty: 1 },
                        ToEmail: { typeName: 'Edm.String', structuralProperty: 1 },
                        ToId: { typeName: 'Edm.String', structuralProperty: 1 },
                        ToEntityName: { typeName: 'Edm.String', structuralProperty: 1 },
                        SubstitutionArguments: { typeName: 'Edm.String', structuralProperty: 1 },
                        TemplateId: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };

        return this.asyncPostMessage(message).toPromise();
    }

    public getExecutionDate(data?: {
        segment?: D365CustomerSegment;
        channelCode?: string;
        signatureType?: D365SignatureType;
        confirmationType?: D365ConfirmationType;
        isImmediateExecution?: boolean;
        signatureDate?: Date;
        letterDate?: Date;
        flowType?: FlowType;
        communicationType?: AptBillType;
        isWinback?: boolean;
    }): Observable<Date> {
        return combineLatest([
            data?.segment ? of(data?.segment) : this.store.select(selectCartSegment),
            data?.channelCode ? of(data?.channelCode) : this.store.select(selectChannelCode),
            data?.confirmationType && data?.signatureType
                ? of({ signatureType: data?.signatureType, confirmationType: data?.confirmationType })
                : this.store.select(v2SelectFirma).pipe(
                      map((signature) => ({
                          signatureType: data?.signatureType || signature?.d365SignatureType,
                          confirmationType: data?.confirmationType || signature?.confirmationType,
                      })),
                  ),
            data?.communicationType
                ? of({ type: data?.communicationType })
                : this.store.select(v2SelectCommunicationMethodAddress('ALL')),
            data?.flowType ? of(data?.flowType) : this.store.select(v2SelectFlowType),
        ]).pipe(
            take(1),
            map(([segment, channelCode, { signatureType, confirmationType }, { type }, flowType]) => ({
                segment,
                channelCode,
                signatureType,
                confirmationType,
                immediateExecution: !!data?.isImmediateExecution,
                isDigitalCommunication: type === AptBillType.Digitale,
                signatureDate: data?.signatureDate,
                letterDate: data?.letterDate,
                operationMode: flowTypeToD365OperationMode(flowType),
                isWinback: data?.isWinback,
            })),
            mergeMap((request) => this.getProcessExecutionDate(request)),
        );
    }

    /**
     * @returns formato data "2020-04-14"
     */
    private getProcessExecutionDate({
        apttusQuoteCode,
        segment,
        channelCode,
        signatureType,
        confirmationType,
        immediateExecution,
        isDigitalCommunication,
        signatureDate,
        operationMode,
        letterDate,
        isWinback,
    }: {
        apttusQuoteCode?: string;
        segment: D365CustomerSegment;
        channelCode: string;
        signatureType?: D365SignatureType;
        confirmationType?: D365ConfirmationType;
        immediateExecution?: boolean;
        isDigitalCommunication?: boolean;
        signatureDate?: Date;
        operationMode: D365OperationMode;
        letterDate?: Date;
        isWinback?: boolean;
    }): Observable<Date> {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: {
                    ApttusQuoteCode: apttusQuoteCode || 'salesApp_quote',
                    CustomerSegment: `${segment || ''}`,
                    ChannelCode: `${channelCode}`,
                    SignatureType: `${signatureType || D365SignatureType.NessunaFirma}`,
                    ConfirmationType: `${confirmationType || D365ConfirmationType.NessunaConferma}`,
                    ImmediateExecution: `${+!!immediateExecution}`,
                    DigitalCommunication: `${+!!isDigitalCommunication}`,
                    SignatureDate: moment(signatureDate || new Date()).format('YYYY-MM-DD'),
                    OperativeModeCode: operationMode,
                    LetterDate: moment(letterDate || new Date()).format('YYYY-MM-DD'),
                    IsWinback: isWinback,
                    ...(this.toggleService.isSwitchInE2EEnabled
                        ? {
                              ProcessStep: 'OE',
                          }
                        : {}),
                },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_getactivationdate',
                    parameterTypes: {
                        ApttusQuoteCode: { typeName: 'Edm.String', structuralProperty: 1 },
                        CustomerSegment: { typeName: 'Edm.String', structuralProperty: 1 },
                        ChannelCode: { typeName: 'Edm.String', structuralProperty: 1 },
                        SignatureType: { typeName: 'Edm.String', structuralProperty: 1 },
                        ConfirmationType: { typeName: 'Edm.String', structuralProperty: 1 },
                        ImmediateExecution: { typeName: 'Edm.String', structuralProperty: 1 },
                        DigitalCommunication: { typeName: 'Edm.String', structuralProperty: 1 },
                        SignatureDate: { typeName: 'Edm.String', structuralProperty: 1 },
                        OperativeModeCode: { typeName: 'Edm.Int32', structuralProperty: 1 },
                        LetterDate: { typeName: 'Edm.String', structuralProperty: 1 },
                        IsWinback: { typeName: 'Edm.Boolean', structuralProperty: 1 },
                        ...(this.toggleService.isSwitchInE2EEnabled
                            ? {
                                  ProcessStep: { typeName: 'Edm.String', structuralProperty: 1 },
                              }
                            : {}),
                    },
                },
            },
        };

        return this.asyncPostMessage<DataEsecuzione>(message).pipe(
            tap((res) => {
                if (!res?.PlannedActivationDate) {
                    throw new Error(res?.ErrorMessage || 'Errore nel recupero data di esecuzione servizio');
                }
            }),
            map(({ PlannedActivationDate }) => moment(PlannedActivationDate).toDate()),
        );
    }

    startGeolocation(): void {
        const message: D365PostMessage = {
            type: 'geolocationrequest',
        };
        this.asyncPostMessage(message).toPromise();
    }

    retrieveRecordAsync(
        entity: string,
        entityGuid: string,
        fields: Array<string>,
        responseType?: string,
    ): Promise<any> {
        const type = (responseType ? responseType : `${entity}response`) + getRandomHash();
        const message: D365PostMessage = {
            type: 'retrieve',
            content: {
                entity,
                guid: entityGuid,
                fields,
                type,
            },
        };
        return this.asyncPostMessage(message).toPromise();
    }

    retrieveMultipleRecordsAsync<S>(
        entity: string,
        filter: string,
        fields: string[],
        responseType?: string,
    ): Observable<S[]> {
        const message: D365PostMessage = {
            type: 'retrievemultiple',
            content: {
                entity,
                filter,
                fields,
                type: responseType || `${entity}response`,
            },
        };

        return this.asyncPostMessage<{ queryresult: [] }>(message).pipe(
            // Retrieving query results
            map((res) => (res?.queryresult || []) as S[]),
            // Mapping out only given fields
            map((records) =>
                fields
                    ? records.map((record) =>
                          fields.reduce(
                              (aggr, field) => ({
                                  ...aggr,
                                  [field]: record[field],
                              }),
                              {} as S,
                          ),
                      )
                    : records,
            ),
        );
    }

    requestApplicationLocationAsync(): Observable<AppLocation> {
        const message: D365PostMessage = { type: 'applocationrequest' };
        return this.asyncPostMessage<{ appLocation: AppLocation }>(message).pipe(map(({ appLocation }) => appLocation));
    }

    /**
     * @description Ricerca del lead e autoassegnazione dello stesso in caso di esito positivo
     * @description uno dei due campi leadId e leadCode è necessario per la action, il campo non utilizzato va passato come ""
     * @param leadId guid del lead su crm
     * @param leadCode Codice Lead
     * @param currentUserId Guid dell'utente corrente - OBBLIGATORIO
     */
    leadSearch(
        leadId: string,
        leadCode: string,
        currentUserId: string,
        currentVirtualAgent: VirtualAgent,
    ): Promise<any> {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: {
                    LeadId: leadId,
                    LeadCode: leadCode,
                    CurrentUserId: currentUserId,
                    CurrentVirtualAgentId: currentVirtualAgent.VirtualAgentId,
                    CurrentVirtualAgencyId: currentVirtualAgent.VirtualAgencyId,
                    CurrentVirtualAgencyChannelCanAutoAssign:
                        currentVirtualAgent.VirtualAgency.Channel.CanAutoAssignLead,
                },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_lead_autoassign',
                    parameterTypes: {
                        LeadId: { typeName: 'Edm.String', structuralProperty: 1 },
                        LeadCode: { typeName: 'Edm.String', structuralProperty: 1 },
                        CurrentUserId: { typeName: 'Edm.String', structuralProperty: 1 },
                        CurrentVirtualAgentId: { typeName: 'Edm.String', structuralProperty: 1 },
                        CurrentVirtualAgencyId: { typeName: 'Edm.String', structuralProperty: 1 },
                        CurrentVirtualAgencyChannelCanAutoAssign: { typeName: 'Edm.Boolean', structuralProperty: 1 },
                    },
                },
            },
        };

        return this.asyncPostMessage(message).toPromise();
    }

    getAgentInfoAsync(): Observable<AgentInfo> {
        const message: D365PostMessage = {
            type: 'agentinforequest',
        };

        return this.store.select(selectAgentInfo).pipe(
            take(1),
            mergeMap((res: AgentInfo) => (res ? of(res) : this.asyncPostMessage<AgentInfo>(message))),
        );
    }

    /**
     * @description Recupero informazioni account
     * @param accountGuid guid dynamics es: 1f3a074f-6085-ea11-a812-000d3a20f252
     * @returns Le informazioni dell'account sono all'interno dell'oggetto di risposta nell'oggetto 'ResponseJson'
     * @deprecated
     */
    getAccountInfo(accountGuid: string): Observable<CustomerInfo> {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: {
                    RequestGuid: accountGuid,
                },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_getaccount',
                    parameterTypes: {
                        RequestGuid: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };
        return this.asyncPostMessage<CustomerInfo>(message, 'ResponseJson');
    }

    /**
     * @description Recupero informazioni account e contatto
     * @deprecated Use apim /d365 api. Ricerca clienti.
     */
    getAccountContactData(accountGUID: string): Observable<D365Account>;
    getAccountContactData(accountCode: string): Observable<D365Account>;
    getAccountContactData(accountNumber: string): Observable<D365Account>;
    getAccountContactData(accountGuidOrCodeOrNumber: string): Observable<D365Account> {
        return this.retrieveMultipleRecordsFetchAsync<D365AccountContactAddressRaw>(
            getAccountContactQuery(accountGuidOrCodeOrNumber),
        ).pipe(
            map((records) =>
                records.reduce(
                    (aggr, r) => ({
                        //#region Customer
                        accountid: r.accountid,
                        egl_customercode: r.egl_customercode,
                        egl_lastname: r.egl_lastname,
                        egl_firstname: r.egl_firstname,
                        name: r.name,
                        egl_taxcode: r.egl_taxcode,
                        egl_vatcode: r.egl_vatcode,

                        //#region Privacy
                        egl_privacy1: flagToBoolOrNull(r.egl_privacy1),
                        egl_privacy2: flagToBoolOrNull(r.egl_privacy2),
                        egl_privacy3: flagToBoolOrNull(r.egl_privacy3),
                        //#endregion

                        // egl_winbackproducttype: r.egl_winbackproducttype,
                        // egl_winback: flagToBoolOrNull(r.egl_winback),
                        egl_preferredchannel: r.egl_preferredchannel,
                        egl_customeraccountcode: r.egl_customeraccountcode,
                        numberofemployees: r.numberofemployees,
                        revenue: r.revenue,
                        preferredcontactmethodcode: r.preferredcontactmethodcode,

                        egl_migration_mastercode: r.egl_migration_mastercode || D365AccountMigrated.D365,
                        egl_customersegmentcode: r.egl_customersegmentcode,

                        //#region contact methods
                        //#region Primary Land line
                        telephone1Prefix: r.telephone1Prefix?.egl_callingcode,
                        telephone1: r.telephone1,
                        //#endregion
                        //#region Secondary Phone
                        telephone2Prefix: r.telephone2Prefix?.egl_callingcode,
                        telephone2: r.telephone2,
                        //#endregion
                        //#region Mobile Phone
                        telephone3Prefix: r.telephone3Prefix?.egl_callingcode,
                        telephone3: r.telephone3,
                        //#endregion
                        //#endregion
                        egl_portalregistration: r.egl_portalregistration,
                        emailaddress1: r.emailaddress1,
                        addresses: {
                            ...(aggr?.addresses || {}),
                            //#region Addresses
                            ...(r.addressTypeRole?.egl_name
                                ? {
                                      [r.addressTypeRole?.egl_name]: {
                                          //addressTypeRole: adressRoles?.addressTypeRole?.egl_name,
                                          egl_code: r.address?.egl_code,
                                          egl_city: r.address?.egl_city,
                                          egl_istatcode: r.address?.egl_istatcode,
                                          egl_country: r.address?.egl_country,
                                          egl_province: r.address?.egl_province,
                                          egl_street: r.address?.egl_street,
                                          egl_streetnumber: r.address?.egl_streetnumber,
                                          egl_zipcode: r.address?.egl_zipcode,
                                          toponym: r.address?.toponym?.egl_name,
                                      },
                                  }
                                : {}),
                            //#endregion
                        },
                        //#endregion

                        contacts: {
                            ...(aggr?.contacts || {}),
                            //#region Contact
                            ...(r.contactRole?.egl_name
                                ? {
                                      [r.contactRole?.egl_name]: {
                                          //contactRole: contact?.contactRole,
                                          lastname: r.contact?.lastname,
                                          firstname: r.contact?.firstname,
                                          egl_taxcode: r.contact?.egl_taxcode,
                                          egl_migration_mastercode:
                                              r.contact?.egl_migration_mastercode || D365AccountMigrated.D365,
                                          egl_contactid: r.contactRelationship?.egl_contactid,
                                      },
                                  }
                                : {}),
                            //#endregion
                        },
                        legalForm: r.legalForm,
                    }),
                    {} as D365Account,
                ),
            ),
        );
    }

    // Esitazione del LEAD
    // leadGuid -> guid dell'utente
    // codicePlico -> codicePlico
    setCampaignResponseCreateOk(
        leadGuid: string,
        codicePlico: string,
        timestamp: string,
        telephone?: string,
    ): Promise<any> {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: {
                    LeadId: leadGuid,
                    DocumentCode: codicePlico,
                    Telephone: telephone,
                    Timestamp: JSON.stringify(timestamp),
                },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_campaignresponse_createok',
                    parameterTypes: {
                        LeadId: { typeName: 'Edm.String', structuralProperty: 1 },
                        DocumentCode: { typeName: 'Edm.String', structuralProperty: 1 },
                        Telephone: { typeName: 'Edm.String', structuralProperty: 1 },
                        Timestamp: { typeName: 'Edm.DateTime', structuralProperty: 1 },
                    },
                },
            },
        };
        return this.asyncPostMessage(message).toPromise();
    }

    private addLeadTopics(interestList: string[]): Observable<{ [key: string]: string }> {
        const filteredInterestList = (interestList || []).filter(Boolean);
        return !filteredInterestList.length
            ? of({} as { [key: string]: string })
            : this.store.select(selectFullState).pipe(
                  take(1),
                  map(({ user, selectedVirtualAgent, orderEntry }) => ({
                      currentUserId: user?.agentInfo?.SystemUserId,
                      virtualAgentId: selectedVirtualAgent?.VirtualAgentId,
                      contactOrLead: {
                          ...(user?.contact || user?.lead),
                          parentAccountId: user?.contact?.accountid || user?.lead?.parentaccountid_value,
                          parentContactId: user?.contact?.contactid || user?.lead?.parentcontactid_value,
                      },
                      segment: user?.cartSegment,
                      privacy: orderEntry?.privacyTrattDatiPers,
                  })),
                  map(({ contactOrLead, segment, privacy, currentUserId, virtualAgentId }) => ({
                      currentUserId,
                      virtualAgentId,
                      leadData: {
                          CustomerSegmentCode: segment,
                          FirstName: contactOrLead?.firstname,
                          LastName: contactOrLead?.lastname,
                          ParentAccountId: contactOrLead?.parentAccountId,
                          ParentContactId: contactOrLead?.parentContactId,
                          TaxCode: contactOrLead?.egl_taxcode,
                          Email: contactOrLead?.emailaddress1,
                          Telephone1: contactOrLead?.mobilephone,
                          Telephone2: contactOrLead?.telephone1,
                          Address: contactOrLead?.address1_line1,
                          City: contactOrLead?.address1_city,
                          PostalCode: contactOrLead?.address1_postalcode,
                          Province: contactOrLead?.address1_stateorprovince,
                          CustomerAccountCode: contactOrLead?.egl_customeraccountcode,
                          CustomerCode: contactOrLead?.egl_customercode,
                          VatCode: contactOrLead?.egl_vatcode,
                          Privacy1: !!privacy[PrivacyType.InizPromozionaliEgl] + '',
                          Privacy2: !!privacy[PrivacyType.AnalisiMercato] + '',
                          Privacy3: !!privacy[PrivacyType.InizPromoTerzeP] + '',
                      },
                  })),
                  mergeMap(({ currentUserId, virtualAgentId, leadData }) =>
                      this.createLeadFromInterest(currentUserId, virtualAgentId, filteredInterestList, leadData),
                  ),
                  map((leadIds) =>
                      filteredInterestList.reduce(
                          (aggr, topicName, index) => ({
                              ...aggr,
                              [topicName]: leadIds[index],
                          }),
                          {} as { [key: string]: string },
                      ),
                  ),
              );
    }

    /**
     * @description Creazione lead da interesse
     * @param interestList Array di stringe degli interessi (es: GAS E LUCE, ASSICURAZIONI)
     * @returns Restituisce, oltre a Result e ErrorMessage, CreatedLeads, all'interno del quale è presente un array di guid dei lead creati su CRM
     */
    createLeadFromInterest(
        currentUserId: string,
        CurrentVirtualAgentId: string,
        interestList: string[],
        leadData: LeadDataForInterest,
    ): Promise<string[]> {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: {
                    CurrentUserId: currentUserId,
                    CurrentVirtualAgentId,
                    InterestList: JSON.stringify(interestList),
                    LeadData: JSON.stringify(leadData),
                },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_createleadfrominterests',
                    parameterTypes: {
                        CurrentUserId: { typeName: 'Edm.String', structuralProperty: 1 },
                        CurrentVirtualAgentId: { typeName: 'Edm.String', structuralProperty: 1 },
                        InterestList: { typeName: 'Edm.String', structuralProperty: 1 },
                        LeadData: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };
        return this.asyncPostMessage<CreateLeadFromInterestsResponse>(message)
            .pipe(
                tap((resp) => {
                    if (resp?.ErrorMessage || !resp?.CreatedLeads) {
                        const defaultMsg = `Errore nella creazione del lead ${leadData?.LastName} ${
                            leadData?.FirstName
                        } per i seguenti interessi: ${interestList?.join(', ')}`;
                        this.logger.error('egl_createleadfrominterests', resp.ErrorMessage || defaultMsg);
                    }
                }),
                map(({ CreatedLeads }) => CreatedLeads),
                map((createdLeads) => createdLeads && <string[]>JSON.parse(createdLeads)),
            )
            .toPromise();
    }

    /**
     * @description Metodo per rimuovere l'interesse di un cliente in fase di ordine
     */
    private removeLeadById(leadId: string): Observable<void> {
        const message: D365PostMessage = {
            type: 'deleterecordrequest',
            content: {
                id: leadId,
                entity: 'lead',
            },
        };
        return this.asyncPostMessage<void>(message);
    }

    private removeLeadTopics(leadIds: string[]): Observable<void> {
        const filteredLeadIds = (leadIds || []).filter(Boolean);
        return filteredLeadIds.length
            ? forkJoin(filteredLeadIds.map((leadId) => this.removeLeadById(leadId))).pipe(map(() => null))
            : of(null);
    }

    /**
     * @returns Work Order Id
     */
    upsertWorkOrder(body: Partial<WorkOrderD365Request>): Observable<string> {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: { UpsertDistributorQuoteRequestRequest: JSON.stringify(body) },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_distributorquoterequest_upsert',
                    parameterTypes: {
                        UpsertDistributorQuoteRequestRequest: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };
        return this.asyncPostMessage<UpsertPreventivoResponse>(message).pipe(
            tap((res) => {
                if (res?.Result === 'KO' || !res?.WorkOrderId) {
                    throw new Error(res?.ErrorMessage);
                }
            }),
            map(({ WorkOrderId }) => WorkOrderId),
        );
    }

    /**
     * @description save quoteId in d365 after the convertCartToQuote
     */
    saveQuoteIdInd365(
        workOrderId: string,
        quoteCode: string,
        quoteName: string,
        gtwDossierCode?: string,
    ): Observable<any> {
        return this.upsertWorkOrder({
            flowCode: WorkOrderFlowCodes.CreazionePratica,
            workOrderId,
            quoteCode,
            quoteName,
            gtwDossierCode,
        });
    }

    saveQuoteIdInd365AumPot(
        workOrderId: string,
        quoteCode: string,
        quoteName: string,
        eglState: Store<EglState>,
        gtwDossierCode?: string,
    ): Observable<any> {
        return combineLatest([eglState.select(v2SelectFlowType), eglState.select(v2SelectFirma)]).pipe(
            map(([flowType, signature]) => {
                if (
                    flowTypeUtil(flowType).equalTo(FlowType.VariazioneTecnicaLavoriPreventivoAumentoPotenza) &&
                    signature?.d365SignatureType === D365SignatureType.PaperPrintedBySystem
                )
                    of({ workOrderId });
                else this.saveQuoteIdInd365(workOrderId, quoteCode, quoteName, gtwDossierCode);
            }),
        );
    }

    // Chiamata al servizio recupero metodi di verifica volontà
    // https://dev.azure.com/DevOps-Applications-EGL/Front%20End%20Eni/_wiki/wikis/Front-End-Eni.wiki/447/Action-per-recupero-metodi-di-verifica-volont%C3%A0
    /**
     * @description: Chiamata al servizio recupero metodi di verifica volontà, utilizzato anche per il recupero dei metodi di firma
     * @param channelCode: Codice Canale es: AGE, TLO, ecc
     * @param signatureType: Tipo di firma es: '100000000', i valori della mappatura si trovano in D365SignatureType
     * @param HasInsurance: Presenza di un assicurazione a carrello
     * @param agencyCode: GUID agenzia
     * @param agentCode: GUID agente
     */
    getCheckType(
        channelCode: string,
        signatureType: D365SignatureType,
        productType: string,
        agencyCode: string,
        agentCode: string,
        operationMode: D365OperationMode,
    ): Observable<{
        signatureType: D365SignatureType;
        canBeModified: boolean;
        isBlocking: boolean;
        confirmationType: D365ConfirmationType;
    }> {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: {
                    ChannelCode: channelCode,
                    SignatureType: `${signatureType}`,
                    ProductType: productType,
                    AgencyCode: agencyCode,
                    AgentCode: agentCode,
                },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_getchecktype',
                    parameterTypes: {
                        ChannelCode: { typeName: 'Edm.String', structuralProperty: 1 },
                        SignatureType: { typeName: 'Edm.String', structuralProperty: 1 },
                        ProductType: { typeName: 'Edm.String', structuralProperty: 1 },
                        AgencyCode: { typeName: 'Edm.String', structuralProperty: 1 },
                        AgentCode: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };
        if (operationMode) {
            message.content.request['OperativeModeCode'] = operationMode;
            message.content.requestmetadata.parameterTypes['OperativeModeCode'] = {
                typeName: 'Edm.Int32',
                structuralProperty: 1,
            };
        }

        return this.asyncPostMessage<CheckTypeResponse>(message).pipe(
            tap(({ Result, ErrorMessage }) => {
                if (Result && Result !== 'OK') {
                    throw new Error(ErrorMessage || 'Nessuna configurazione attiva');
                }
            }),
            map(({ CanBeModified, ConfirmationType, IsBlocking }) => ({
                signatureType,
                canBeModified: flagToBool(CanBeModified),
                isBlocking: flagToBool(IsBlocking),
                confirmationTypeCode: ConfirmationType,
                confirmationType: this.parseD365ConfirmationType(ConfirmationType),
            })),
            tap(({ confirmationType, confirmationTypeCode }) => {
                if (typeof confirmationType !== 'number') {
                    throw new Error(`Modalità di firma non gestita: ${confirmationTypeCode}`);
                }
            }),
            map(({ confirmationTypeCode, ...data }) => data),
        );
    }

    private parseD365ConfirmationType(confirmationType: string | number | D365ConfirmationType): D365ConfirmationType {
        const intValue: number = parseInt(`${confirmationType || 0}`);
        return !isNaN(intValue) && Object.values(D365ConfirmationType).includes(intValue)
            ? (intValue as D365ConfirmationType)
            : null;
    }

    /**
     * @description Lista di tutti i Topic arricchiti con il campo checked = true per quelli presenti
     */
    public getLeadTopic(): Observable<LeadTopic[]> {
        // VARIANTE CON RECUPERO DEI LEADIDS GIA' CREATI
        // NB: NON APPROVATA
        /* return combineLatest([
            this.getTopics(),
            this.store.select(selectUserState).pipe(
                take(1),
                map((user) => user?.customerInfo?.CustomerCode || user?.contact?.egl_customercode),
                mergeMap((customerCode) => this.getCustomerLeadIds(customerCode))
            ),
        ]).pipe(
            map(([topics, customerLeadIds]) =>
                topics.map((topic) => ({
                    ...topic,
                    Checked: !!customerLeadIds[topic.LeadTopicId],
                    LeadId: customerLeadIds[topic.LeadTopicId],
                }))
            )
        ); */
        return this.getTopics().pipe(
            map((topics) =>
                topics.map((topic) => ({
                    ...topic,
                    Checked: false,
                })),
            ),
        );
    }

    public setLeadTopic(
        newTopics: { [k: string]: boolean },
        leadTopics?: LeadTopic[],
        newTopicsLegacy?: string[],
    ): Observable<LeadTopic[]> {
        return (leadTopics ? of(leadTopics) : this.getLeadTopic()).pipe(
            map((topics) =>
                topics.reduce(
                    (aggr, topic) => ({
                        ...aggr,
                        added: [...aggr.added, !topic.Checked && newTopics[topic.LeadTopicId] ? topic.Name : null],
                        removed: [
                            ...aggr.removed,
                            topic.Checked && !newTopics[topic.LeadTopicId] ? topic.LeadId : null,
                        ],
                    }),
                    { added: [], removed: [], topics } as { added: string[]; removed: string[]; topics: LeadTopic[] },
                ),
            ),
            map(({ added, removed, topics }) => {
                if (!_.isEmpty(newTopicsLegacy)) {
                    added = newTopicsLegacy;
                    return { added, removed, topics };
                }
                return { added, removed, topics };
            }),
            mergeMap(({ added, removed, topics }) =>
                forkJoin([this.addLeadTopics(added), this.removeLeadTopics(removed)]).pipe(
                    map(([addedLeadIdsMap]) =>
                        topics.map((topic) => {
                            const leadId =
                                addedLeadIdsMap[topic.Name] || (!removed.includes(topic.LeadId) && topic.LeadId);
                            return {
                                ...topic,
                                Checked: !!leadId,
                                LeadId: leadId,
                            };
                        }),
                    ),
                ),
            ),
        );
    }

    /**
     * @returns Recupera lista degli interessi attualmente caricati su d365
     */
    private getTopics(): Observable<LeadTopic[]> {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: {},
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_getleadtopic',
                    parameterTypes: {},
                },
            },
        };
        return this.asyncPostMessage<LeadTopic[]>(message, 'LeadTopicList');
    }

    private getCustomerLeadIds(customerCode: string): Observable<{
        [leadTopicId: string]: string; //leadTopicId è l'id univoco del topic, il valore è l'id univoco cliente/topic
    }> {
        return this.retrieveMultipleRecordsFetchAsync(LEAD_IDS_BY_CUSTOMER, { customerCode }).pipe(
            map((results) =>
                results.reduce(
                    (aggr, { leadid, _egl_leadtopicid_value }) => ({
                        ...aggr,
                        [_egl_leadtopicid_value]: leadid,
                    }),
                    {} as { [k: string]: string },
                ),
            ),
        );
    }

    /**
     * @description Recupero sede operativa storicizzata
     * @returns restituisce l'agencybranch a
     */
    getAgencyBranch(virtualAgent: VirtualAgent, date: string, dispatch?: boolean): Observable<AgencyBranch> {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: {
                    VirtualAgentId: virtualAgent.VirtualAgentId,
                    Date: date,
                },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_virtualagent_getagencybranch',
                    parameterTypes: {
                        VirtualAgentId: { typeName: 'Edm.String', structuralProperty: 1 },
                        Date: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };

        return this.asyncPostMessage<AgencyBranch>(message).pipe(
            tap((res) => {
                if (res?.Result !== 'OK') {
                    this.logger.warn(
                        `Non è stata trovata una sede operativa per l'agente ${virtualAgent.AgentCode} per la data ${date}`,
                        false,
                    );
                }
            }),
        );
    }

    // esempio risposta KO
    // "Result": "KO",
    // "ErrorMessage": "Il formato codice plico fornito non coincide con quanto presente a sistema per i seguenti parametri: Channel Teleselling outbound, SignatureTypeCode 100000004, OperativeModeCode 100000004"
    // esempio risposta OK
    // "Result": "OK",
    // "ErrorMessage": ""

    /**
     * @description Verifica del codice plico inserito
     * @param contractCode Codice Plico
     * @param signatureTypeCode tipo di firma
     * @param operationModeCode modalità operativa
     */
    checkCodicePlico(
        contractCode: string,
        channel: string,
        signatureTypeCode: D365SignatureType,
        operationModeCode: D365OperationMode,
    ): Promise<BaseD365Response> {
        const type = 'checkcontractcoderesponse' + getRandomHash();
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: {
                    ContractCode: contractCode,
                    Channel: channel,
                    SignatureTypeCode: signatureTypeCode.toString(),
                    OperativeModeCode: operationModeCode.toString(),
                },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_validatecontractcode',
                    parameterTypes: {
                        ContractCode: { typeName: 'Edm.String', structuralProperty: 1 },
                        Channel: { typeName: 'Edm.String', structuralProperty: 1 },
                        SignatureTypeCode: { typeName: 'Edm.String', structuralProperty: 1 },
                        OperativeModeCode: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };

        return this.asyncPostMessage<BaseD365Response>(message).toPromise();
    }

    /**
     * @description Action di recuperoconfigurazione Canale
     * @param channel ChannelCode, es: TLI
     * @returns
     */
    getChannelMarketConfiguration(
        channel: string | D365ChannelCode,
        customerSegment: D365CustomerSegment,
    ): Promise<ChannelMarketConfigurationResponse> {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: {
                    Channel: channel,
                    Market: +customerSegment,
                },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_channelmarketconfiguration_getconfiguration',
                    parameterTypes: {
                        Channel: { typeName: 'Edm.String', structuralProperty: 1 },
                        Market: { typeName: 'Edm.Int32', structuralProperty: 1 },
                    },
                },
            },
        };

        return combineLatest([
            this.store.pipe(take(1), select(selectFullState)),
            this.asyncPostMessage<ChannelMarketConfigurationResponse>(message),
        ])
            .pipe(
                map(([state, res]) => ({
                    agentInfo: clone(state?.user?.agentInfo),
                    res,
                    canSkipExecution:
                        (res?.ChannelMarketConfiguration
                            ? (JSON.parse(res.ChannelMarketConfiguration) as ChannelMarketConfiguration)
                            : null
                        )?.MustSkipImmediateExecution === 1,
                })),
                map(({ res, agentInfo, canSkipExecution }) => ({
                    res,
                    agentInfo: res
                        ? ({
                              ...(agentInfo || {}),
                              VirtualAgents: (agentInfo?.VirtualAgents || []).map((virtualAgent) => ({
                                  ...(virtualAgent || {}),
                                  VirtualAgency: {
                                      ...(virtualAgent?.VirtualAgency || {}),
                                      Channel: {
                                          ...(virtualAgent?.VirtualAgency?.Channel || {}),
                                          CanSkipExecution: canSkipExecution,
                                      },
                                  },
                              })),
                          } as AgentInfo)
                        : null,
                    passaggioRapido: canSkipExecution
                        ? Object.assign(new TipoEsecuzione(), {
                              passaggioRapidoCommodity: false,
                              passaggioRapidoManutenzione: false,
                          })
                        : null,
                })),
                tap(({ passaggioRapido, agentInfo }) => {
                    if (passaggioRapido) {
                        this.store.dispatch(setPassaggioRapido({ payload: passaggioRapido }));
                    }
                    if (agentInfo) {
                        this.store.dispatch(setAgentInfo({ a: agentInfo }));
                    }
                }),
                map(({ res }) => res),
            )
            .toPromise();
    }

    getSkipConfiguration(): void {
        combineLatest([this.store.select(selectCurrentVirtualAgent), this.store.select(selectContactLead)])
            .pipe(take(1))
            .subscribe(([virtualAgent, contactLead]) => {
                const channelCode = virtualAgent?.VirtualAgency?.Channel?.Code;
                const customerSegment =
                    (contactLead?.contact || contactLead?.lead)?.egl_customersegmentcode ||
                    D365CustomerSegment.Residential;

                if (!channelCode) {
                    this.logger.error(null, 'channelCode non valorizzato');
                } else {
                    this.getChannelMarketConfiguration(channelCode, customerSegment);
                }
            });
    }

    setUserConfiguration(userId: string, lastCustomerSegment: D365CustomerSegment): Promise<boolean> {
        const userConfiguration = {
            UserId: userId,
            LastUsedCustomerSegment: lastCustomerSegment,
        };
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: {
                    UserConfiguration: JSON.stringify(userConfiguration),
                },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_systemuserconfiguration_setuserconfiguration',
                    parameterTypes: {
                        UserConfiguration: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };

        return this.asyncPostMessage<BaseD365Response>(message)
            .pipe(
                map((res) => res?.Result !== 'OK' && res?.ErrorMessage),
                tap((errorMessage) => {
                    if (errorMessage) {
                        this.logger.error(null, "Errore nell'impostazione del mercato dell'agente", errorMessage, true);
                    }
                    this.store.dispatch(setLastUsedCustomerSegment({ s: lastCustomerSegment }));
                }),
                map((errorMessage) => !errorMessage),
            )
            .toPromise();
    }

    setVirtualAgentConfiguration(virtualAgentId: string, isInStore: boolean): Promise<boolean> {
        const VirtualAgentConfiguration = {
            VirtualAgentIdString: virtualAgentId,
            IsInStore: isInStore,
        };
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: {
                    ConfigurationJsonString: JSON.stringify(VirtualAgentConfiguration),
                },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_setvirtualagentconfiguration',
                    parameterTypes: {
                        ConfigurationJsonString: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };

        return this.asyncPostMessage<BaseD365Response>(message)
            .pipe(
                map((res) => res?.Result !== 'OK' && res?.ErrorMessage),
                tap((errorMessage) => {
                    if (errorMessage) {
                        this.logger.error(
                            null,
                            "Errore nell'impostazione della configurazione dell'agente virtuale",
                            errorMessage,
                            true,
                        );
                    }
                }),
                map((errorMessage) => !errorMessage),
            )
            .toPromise();
    }

    openLookupObject(
        entity: string,
        fields: string[] = [],
        multiSelect: boolean = false,
        viewId?: string[],
        searchText: string = '',
        lookupFilters?: LookupFilter[],
    ): Promise<any> {
        if (!viewId) {
            viewId = ['00000000-0000-0000-0000-000000000000'];
        }

        const message: D365PostMessage = {
            type: 'openlookupobject',
            content: {
                entity,
                fields,
                multiselect: multiSelect,
                viewid: viewId,
                searchtext: searchText,
                filters: lookupFilters,
            },
        };

        return this.asyncPostMessage(message).toPromise();
    }

    getAssetGuid(assetId): Promise<any> {
        return this.retrieveMultipleRecordsAsync('egl_asset', `egl_cpqid eq ${assetId}`, ['egl_assetid']).toPromise();
    }

    getStatusCheckCall(cpqQuoteId: string, quoteName: string): Observable<StatusCheckType> {
        return this.retrieveMultipleRecordsAsync<StatusCheckType>(
            'egl_checkcall',
            `egl_cpqquoteid eq '${cpqQuoteId}' or egl_cpqproposalcode eq '${quoteName}'`,
            ['egl_checkcallid', 'egl_code', 'statuscode'],
        ).pipe(
            take(1),
            tap((r) => console.log('getStatusCheckCall result:', r)),
            map((r) => (r.length > 0 ? r[0] : null)),
        );
    }

    getLegalForm(): Observable<LegalForm[]> {
        return this.retrieveMultipleRecordsAsync<LegalForm>('egl_accountlegalform', 'statecode eq 0', [
            'egl_name',
            'egl_code',
        ]);
    }

    /**
     * @description Retrieve dei valori di tensione
     */
    getVoltageLevel(): Promise<VoltageLevel[]> {
        return this.retrieveMultipleRecordsAsync<{ egl_name: string; egl_code: string }>(
            'egl_voltagelevel',
            'statecode eq 0',
            ['egl_name', 'egl_code'],
        )
            .pipe(map((results) => results.map(({ egl_code, egl_name }) => new VoltageLevel(egl_code, +egl_name))))
            .toPromise();
    }

    /**
     * @description Retrieve Ateco
     */
    getAteco(codes?: string[], full?: boolean): Observable<Ateco[]> {
        return this.retrieveMultipleRecordsAsync<{
            egl_name: string;
            egl_code: string;
            egl_productactivity?: string;
            egl_productsector?: string;
        }>(
            'egl_ateco',
            !!codes?.length ? "egl_code eq '" + codes.join("' or egl_code eq '") + "'" : null,
            full ? ['egl_name', 'egl_code', 'egl_productactivity', 'egl_productsector'] : ['egl_name', 'egl_code'],
        ).pipe(
            map((results) =>
                results.map(
                    ({ egl_code, egl_name, egl_productactivity, egl_productsector }) =>
                        new Ateco(egl_code, egl_name, egl_productactivity, egl_productsector),
                ),
            ),
        );
    }

    /**
     * @description Retrieve di potenza utilizzo
     */
    getPowerConsumption(): Promise<PowerConsumption[]> {
        return this.retrieveMultipleRecordsAsync('egl_powerconsumption', 'statecode eq 0', [
            'egl_name',
            'egl_code',
            'egl_pd2transcoding',
        ])
            .pipe(
                map((results) =>
                    results.map(
                        ({ egl_code, egl_name, egl_pd2transcoding }) =>
                            new PowerConsumption(+egl_name, egl_code, +egl_pd2transcoding),
                    ),
                ),
            )
            .toPromise();
    }

    /**
     * @description Download dei file attraverso api nativa di dynamics365
     * @param base64File
     * @param filename with extenction
     * @param mime
     * @param saveFile open or save file
     */
    downloadFile(base64File: string | ArrayBuffer | null, filename: string, mime: string, saveFile: boolean) {
        const message: D365PostMessage = {
            type: 'downloadfile',
            content: {
                file: {
                    fileContent: base64File,
                    fileSize: 0,
                    mimeType: mime,
                    fileName: filename,
                },
                openFileOptions: {
                    openMode: saveFile ? 2 : 1,
                },
            },
        };

        this.asyncPostMessage(message);
    }

    retrieveMultipleRecordsFetchAsync<T = any>(query: FetchXmlQuery, params?: FetchXmlParams): Observable<T[]> {
        const { entity, fetchxml } = getFetchXmlQuery(query, params);
        const message: D365PostMessage = {
            type: 'retrievemultiplefetch',
            content: {
                entity,
                fetchxml,
            },
        };
        return this.asyncPostMessage<{ queryresult: [] }>(message).pipe(
            map((response) => response?.queryresult || []),
            map((results) => results.map((record) => d365ResponseSugarHam<T>(record))),
        );
    }

    getToponyms(): Observable<string[]> {
        return this.retrieveMultipleRecordsAsync('egl_toponym', 'statecode eq 0', ['egl_name']).pipe(
            map((result) => result.map(({ egl_name }) => egl_name)),
        );
    }

    /**
     * @description Mostra un iFrame che consente di scattare una foto oppure selezionare un file da device (utilizza api Xrm.Device.captureImage per scattare una foto).
     * https://docs.microsoft.com/en-us/powerapps/developer/model-driven-apps/clientapi/reference/xrm-device/captureimage
     * @cOption capture avvia solo la CAMERA, pickFile avvia solo la ricerca in dispositovo.
     * in caso di paramentro vuoto scelta attraverso modale
     */
    deviceSelectFile(cOption?: 'capture' | 'pickFile', cAccept?: string): Promise<[SelectFileResponse]> {
        const message: D365PostMessage = {
            type: 'selectFile',
            content: {
                option: cOption,
                accept: cAccept,
            },
        };
        return this.asyncPostMessage<[SelectFileResponse]>(message).toPromise();
    }

    /**
     * Restituisce la lista dei materiali formativi visibili all'utente selezionato
     * @param currentUserId identificativo agente connesso
     */
    getKnowledgeMaterialList(
        currentUserId: string,
        segment: D365CustomerSegment,
    ): Promise<KnowledgeMaterialListItem[]> {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: { userid: currentUserId, customerSegmentCode: segment },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_knowledgematerial_getlist',
                    parameterTypes: {
                        userid: { typeName: 'Edm.String', structuralProperty: 1 },
                        customerSegmentCode: { typeName: 'Edm.Int32', structuralProperty: 1 },
                    },
                },
            },
        };

        return this.asyncPostMessage<KnowledgeMaterialListItem[]>(message, 'dataJson').toPromise();
    }

    /**
     * Restituisce il base64 di un determinato file formativo presente su d365
     * @param id identificativo documento restituito dalla 'getKnowledgeMaterialList'
     */
    downloadKnowledgeMaterial(id: string): Promise<SelectFileResponse> {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: { knowledgematerialid: id },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_knowledgematerial_downloadfile',
                    parameterTypes: {
                        knowledgematerialid: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };
        return this.asyncPostMessage<SelectFileResponse>(message)
            .pipe(
                map(
                    (res) =>
                        res && {
                            fileContent: res.fileContent,
                            fileName: res.fileName,
                            fileSize: res.fileSize,
                            mimeType: res.mimeType,
                        },
                ),
            )
            .toPromise();
    }

    /**
     * Restituisce la lista dei materiali formativi visibili all'utente selezionato
     * @param currentUserId identificativo agente connesso
     */
    getAssetStructure(assetIntegrationId: string): Observable<Omit<AssetDetailResponse, keyof BaseApiResponse>> {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: { AssetIntegrationId: assetIntegrationId },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_getassetstructure',
                    parameterTypes: {
                        AssetIntegrationId: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };

        return this.asyncPostMessage<AssetDetailResponse>(message, 'AssetStructure').pipe(
            tap((response) => {
                if (response?.status !== StatusResponse.Success) {
                    return new Error(response?.errorManagement?.errorDescription);
                }
            }),
            map(({ response, errorManagement, status, result, ...assetDetail }) => assetDetail),
        );
    }

    getAgentList(query: string, maxNumber?: number): Promise<AgentSearchResponse> {
        const type = 'agentSearchBO';
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: {
                    MaxRecords: maxNumber || 20,
                    FilterAgent: query,
                },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_agent_search',
                    parameterTypes: {
                        FilterAgent: { typeName: 'Edm.String', structuralProperty: 1 },
                        MaxRecords: { typeName: 'Edm.Int32', structuralProperty: 1 },
                    },
                },
            },
        };

        return this.asyncPostMessage<AgentSearchResponse>(message, 'AgentSearch').toPromise();
    }

    getAgencyBranchList(query: string, maxNumber?: number): Promise<AgencyBranchSearchResponse> {
        const type = 'agencyBranchSearchBO';
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: {
                    MaxRecords: maxNumber || 20,
                    FilterAgencyBranch: query,
                },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_agencybranch_search',
                    parameterTypes: {
                        FilterAgencyBranch: { typeName: 'Edm.String', structuralProperty: 1 },
                        MaxRecords: { typeName: 'Edm.Int32', structuralProperty: 1 },
                    },
                },
            },
        };

        return this.asyncPostMessage<AgencyBranchSearchResponse>(message, 'AgencyBranchSearchResult').toPromise();
    }

    getChannelList(query: string, maxNumber?: number): Promise<ChannelListSearchResponse> {
        const type = 'channelListBO';
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: {
                    MaxRecords: maxNumber || 20,
                    FilterChannel: query,
                },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_channel_search',
                    parameterTypes: {
                        FilterChannel: { typeName: 'Edm.String', structuralProperty: 1 },
                        MaxRecords: { typeName: 'Edm.Int32', structuralProperty: 1 },
                    },
                },
            },
        };

        return this.asyncPostMessage<ChannelListSearchResponse>(message, 'ChannelSearchResult').toPromise();
    }

    getVirtualAgencyList(query: string, maxNumber?: number): Promise<VirtualAgencyListSearchResponse> {
        const type = 'virtualAgencyListBO';
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: {
                    MaxRecords: maxNumber || 20,
                    FilterVirtualAgency: query,
                },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_virtualagency_search',
                    parameterTypes: {
                        FilterVirtualAgency: { typeName: 'Edm.String', structuralProperty: 1 },
                        MaxRecords: { typeName: 'Edm.Int32', structuralProperty: 1 },
                    },
                },
            },
        };

        return this.asyncPostMessage<VirtualAgencyListSearchResponse>(message, 'VirtualAgencySearchResult').toPromise();
    }

    /**

     * @description Recupera da D365 la lista dei prefissi telefonici internazionali (+39, +34, ...)
     * @returns Promise<PhonePrefix>
     */
    getInternationalPhonePrefixes(): Promise<PhonePrefix[]> {
        return this.retrieveMultipleRecordsAsync<PhonePrefix>('egl_countrycallingcodes', 'statecode eq 0', [
            'egl_callingcode',
            'egl_country',
            'egl_countrycode',
        ]).toPromise();
    }

    /**
     * Setta la sede operativa offerta dell'agente virtuale selezionato
     * @param virtualAgentId id dell'agente virtuale
     * @param agencyBranchId id della sede operativa offerta
     * @returns Promise<boolean> restutuisce true se l'api di salvataggio ha funzionato correttamente
     */
    setCurrentAgencyBranch(virtualAgentId: string, agencyBranch: AgencyBranchForMonitoring): Promise<boolean> {
        const type = 'setcurrentagencybranch' + getRandomHash();

        const message = {
            type: 'executeaction',
            content: {
                request: {
                    VirtualAgentId: virtualAgentId,
                    AgencyBranchId: agencyBranch?.AgencyBranchId,
                },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_setvirtualagentcurrentagencybranch',
                    parameterTypes: {
                        VirtualAgentId: { typeName: 'Edm.String', structuralProperty: 1 },
                        AgencyBranchId: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };

        return this.asyncPostMessage<BaseD365Response>(message)
            .pipe(
                map((res) => res?.Result !== 'OK' && res?.ErrorMessage),
                tap((errorMessage) => {
                    if (errorMessage) {
                        this.logger.error(
                            null,
                            "Errore nell'impostazione della sede operativa offerta dell'agente",
                            errorMessage,
                            true,
                        );
                    } else {
                        this.store.dispatch(setVirtualAgentAgencyCurrentBranch({ payload: agencyBranch }));
                    }
                }),
                map((errorMessage) => !errorMessage),
            )
            .toPromise();
    }

    /**
     * Chiama l'api nativa navigateTo di d365, documentazione microsoft: https://docs.microsoft.com/en-us/powerapps/developer/model-driven-apps/clientapi/reference/xrm-navigation/navigateto
     * @param req id dell'agente virtuale
     * @returns Promise<boolean> restituisce se la modale è stata aperta correttamente
     */
    async navigateTo(req: NavigateToRequest): Promise<boolean> {
        const type = 'navigateTo' + getRandomHash();
        var content = req;
        content['type'] = type;

        const message: D365PostMessage = { type: 'navigateTo', content };

        return this.asyncPostMessage<NavigateToResponse>(message)
            .pipe(
                map((res) => res?.navigationOk),
                tap((resultOk) => {
                    if (!resultOk) {
                        this.logger.error(null, "Errore nell'apertura della modale", null, true);
                    }
                }),
            )
            .toPromise();
    }

    getSecurityDeposit(CodiceFornitura: string, CodicePdf: string): Observable<SecurityDeposit> {
        const jsonDataInput = JSON.stringify({
            CodiceFornitura,
            CodicePdf,
        });
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: { actionName: 'GETSECURITYDEPOSIT', jsonDataInput },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_ClientAction',
                    parameterTypes: {
                        actionName: { typeName: 'Edm.String', structuralProperty: 1 },
                        jsonDataInput: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };
        return this.asyncPostMessage<SecurityDepositResponse>(message).pipe(
            map((res) => JSON.parse(res?.jsonDataOutput)),
            tap((res: SecurityDeposit) => {
                if (!res?.Deposito) {
                    this.logger.error(
                        this.translateSrv.instant('ASSET_VIEW.LOG_TITLE.SECURITY_DEPOSIT'),
                        this.genericApiError,
                        res?.errorDescription || this.genericApiError,
                        true,
                    );
                }
            }),
        );
    }

    getD365AddressCode(addressExternalId: string): Observable<string> {
        return this.retrieveMultipleRecordsFetchAsync(getD365AddressCodeQuery(addressExternalId)).pipe(
            map((results) => ((results || [])[0] || ({} as any)).egl_code),
        );
    }

    /**
     * @param assetIds Array di assetId d365 (GUUID) oppure assetId SalesForce
     */
    getD365AssetXML(assetIds: string[]): Observable<D365Asset[]> {
        // Filtro la lista degli ids tenendo solo quelli di D365 e utilizzo set per rimuovere duplicati e ritrasformo tutto in array
        const d365AssetIds = Array.from(
            new Set((assetIds || []).filter((assetId) => Regex.D365_GUID_MATCHER.test(assetId))),
        );
        // Filtro la lista degli ids tenendo solo quelli di CPQ e utilizzo set per rimuovere duplicati e ritrasformo tutto in array
        const apttusAssetIds = Array.from(
            new Set((assetIds || []).filter((assetId) => Regex.SALESFORCE_ID_MATCHER.test(assetId))),
        );

        return this.getAssets(d365AssetIds, apttusAssetIds);
    }

    /**
     * @param AssetIds lista degli ids di D365
     * @param CPQIds lista degli ids di CPQ
     */
    getAssets(AssetIds: string[], CPQIds: string[]): Observable<D365Asset[]> {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: {
                    AssetIds: AssetIds?.join(';') || '',
                    CPQIds: CPQIds?.join(';') || '',
                },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_asset_getassets',
                    parameterTypes: {
                        AssetIds: { typeName: 'Edm.String', structuralProperty: 1 },
                        CPQIds: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };

        return this.asyncPostMessage<BaseD365Response & { AssetData: string }>(message).pipe(
            map((response) => (response.Result === 'OK' ? JSON.parse(response.AssetData) : [])),
        );
    }

    /**
     * @description Retrieve degli alert presenti a sistema
     */
    getAlerts(): Promise<Alert[]> {
        const date = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');
        const filter = `statuscode eq 2 and Microsoft.Dynamics.CRM.OnOrAfter(PropertyName='egl_expireddate',PropertyValue='${date}')&$top=5&$orderby=modifiedon desc`;
        return this.retrieveMultipleRecordsAsync('egl_alert', filter, ['egl_alertid', 'egl_name', 'egl_description'])
            .pipe(
                map((results) =>
                    results.map(
                        ({ egl_alertid, egl_name, egl_description }) =>
                            new Alert(egl_name, egl_description, egl_alertid),
                    ),
                ),
            )
            .toPromise();
    }

    /**
     * @description Retrieve delle news presenti a sistema
     */
    getNews(): Promise<WidgetListItem[]> {
        const date = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');
        const filter = `statuscode eq 2 and Microsoft.Dynamics.CRM.OnOrAfter(PropertyName='egl_expireddate',PropertyValue='${date}')&$orderby=modifiedon desc`;
        return this.retrieveMultipleRecordsAsync('egl_news', filter, ['egl_newsid', 'egl_name'])
            .pipe(
                map((results) =>
                    results.map(
                        ({ egl_newsid, egl_name }) =>
                            new WidgetListItem(
                                egl_newsid,
                                egl_name,
                                'WIDGET.NEWS_OMNICHANNEL.LINK_LABEL',
                                'single-light-right',
                                'newspaper',
                            ),
                    ),
                ),
            )
            .toPromise();
    }

    /**
     * @description Retrieve degli articoli di knwowledge base presenti a sistema
     */
    getKnowledgeArticles(): Promise<WidgetListItem[]> {
        const filter = `statuscode eq 7`;
        return this.retrieveMultipleRecordsAsync('knowledgearticle', filter, ['knowledgearticleid', 'title'])
            .pipe(
                map((results) =>
                    results.map(
                        ({ knowledgearticleid, title }) =>
                            new WidgetListItem(
                                knowledgearticleid,
                                title,
                                'WIDGET.ARTICLES.LINK_LABEL',
                                'single-light-right',
                                'form-check-box-1',
                            ),
                    ),
                ),
            )
            .toPromise();
    }

    /**
     * @description Retrieve dei bookmark degli articoli di knwowledge base presenti a sistema
     */
    getBookmarks(): Promise<WidgetListItem[]> {
        const filter = `statuscode eq 1 and _egl_knowledgearticleid_value ne null&$expand=egl_knowledgearticleid($select=title)`;
        return this.retrieveMultipleRecordsAsync('egl_bookmark', filter, ['egl_knowledgearticleid'])
            .pipe(
                map((results) =>
                    results.map(
                        ({ egl_knowledgearticleid }) =>
                            new WidgetListItem(
                                egl_knowledgearticleid.knowledgearticleid,
                                egl_knowledgearticleid.title,
                                'WIDGET.FAVORITEARTICLES.LINK_LABEL',
                                'single-light-right',
                                'star_outline',
                            ),
                    ),
                ),
            )
            .toPromise();
    }

    /**
     * @description Ritorna l'id della lingua di d365
     * @param languageCode intero rappresentante il codice della lingua standard di microsoft, default 1040 per italiano
     * @returns Promise<string> restituisce l'id della lingua
     */
    getLocaleId(languageCode: number = 1040): Promise<string> {
        const filter = `localeid eq ${languageCode}`;
        return this.retrieveMultipleRecordsAsync('languagelocale', filter, ['languagelocaleid'])
            .pipe(
                take(1),
                map((results: { languagelocaleid: string }[]) =>
                    results.length != 0 ? results[0].languagelocaleid : null,
                ),
            )
            .toPromise();
    }

    /**
     * @description Ritorna le categorie dei knowledge article presenti a sistema
     * @param languageCodeId id della lingua per cui si vuole recuperare le catagorie
     * @returns Promise<KnowledgeSearchCategory[]> restituisce le categorie
     */
    getCategoriesForKnowledgeSearch(): Promise<KnowledgeSearchCategory[]> {
        const filter = `(categoryid ne null)&$expand=knowledgearticle_category($select=majorversionnumber,minorversionnumber,modifiedon,title,description;$filter=statuscode eq 7)`;
        return this.retrieveMultipleRecordsAsync('category', filter, [
            'categoryid',
            'description',
            'egl_lastchild',
            '_parentcategoryid_value',
            'title',
            'sequencenumber',
            'knowledgearticle_category',
        ])
            .pipe(
                map((results) =>
                    results.map(
                        ({
                            categoryid,
                            description,
                            egl_lastchild,
                            _parentcategoryid_value,
                            title,
                            sequencenumber,
                            knowledgearticle_category,
                        }) =>
                            new KnowledgeSearchCategory(
                                categoryid,
                                description,
                                egl_lastchild,
                                sequencenumber,
                                title,
                                _parentcategoryid_value,
                                knowledgearticle_category,
                            ),
                    ),
                ),
            )
            .toPromise();
    }

    /**
     * @description Chiama l'api nativa per i suggerimenti di ricerca della relevance search (https://docs.microsoft.com/en-us/power-apps/developer/data-platform/webapi/relevance-search)
     * @param suggestSearch oggetto suggestSeach
     * @returns Promise<any> restituisce i risultati di ricerca
     */
    async getSuggestion(suggestSearch: SuggestSearchRequest): Promise<SuggestSearchResponse> {
        return this.asyncPostMessage<SuggestSearchResponse>(<D365PostMessage>{
            type: 'suggest',
            content: JSON.stringify(suggestSearch),
        }).toPromise();
    }

    /**
     * @description Chiama l'api nativa per la ricerca della relevance search (https://docs.microsoft.com/en-us/power-apps/developer/data-platform/webapi/relevance-search)
     * @param querySearch oggetto suggestSeach
     * @returns Promise<any> restituisce i risultati di ricerca
     */
    async relevanceSearch(querySearch: QuerySearchRequest): Promise<any> {
        return this.asyncPostMessage(<D365PostMessage>{
            type: 'search',
            content: JSON.stringify(querySearch),
        }).toPromise();
    }

    /**
     * @description Chiama l'api egl_migration_getnewaccountstrategy per determinare se il cliente va gestito a nuovo o a vecchio
     * @param operationMode operation mode di d365
     * @param CfPiva può assumere come valore un codice fiscale o Piva
     * @returns Observable<MigrationNewAccountStrategyResponse> restituisce i risultati di ricerca
     */
    getNewAccountStrategy(
        operationMode: D365OperationMode,
        CfPiva?: string,
    ): Observable<MigrationNewAccountStrategyResponse> {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: { OperationMode: operationMode, TaxOrVatCode: CfPiva },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_migration_getnewaccountstrategy',
                    parameterTypes: {
                        OperationMode: { typeName: 'Edm.Int32', structuralProperty: 1 },
                        TaxOrVatCode: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };
        return this.asyncPostMessage<MigrationNewAccountStrategyResponse>(message);
    }

    /**
     * Apre una pagina web
     * @param url url della pagina web da aprire
     * @param target target, default è _blank
     * @returns
     */
    openWebPage(url: string, target: string = '_blank') {
        return this.asyncPostMessage(<D365PostMessage>{
            type: 'openWebPage',
            content: JSON.stringify({
                url: url,
                target: target,
            }),
        });
    }

    /**
     * @description Restituisce tutti incidents
     */
    retrieveMultipleIncidents(params: FetchXmlParams): Observable<D365Incident[]> {
        const { entity, fetchxml } = getFetchXmlQuery(INCIDENTS_BY_CUSTOMER_CODE, params);
        const message: D365PostMessage = {
            type: 'retrievemultiplefetch',
            content: {
                entity,
                fetchxml,
            },
        };
        return this.asyncPostMessage<{ queryresult: [] }>(message).pipe(
            map((response) => response?.queryresult || []),
            map((results) =>
                results.map((incident) => plainToClass(D365Incident, incident, { excludeExtraneousValues: true })),
            ),
        );
    }

    /**
     * @description Restituisce tutti i campi necessari per chiudere un case
     */
    retrieveUpsertCloseCase(params: FetchXmlParams): Observable<D365RetrieveUpsertCase> {
        const { entity, fetchxml } = getFetchXmlQuery(CASE_DATA_BY_CASE_ID, params);
        const message: D365PostMessage = {
            type: 'retrievemultiplefetch',
            content: {
                entity,
                fetchxml,
            },
        };
        return this.asyncPostMessage<{ queryresult: {} }>(message).pipe(
            map((response) => response?.queryresult || {}),
            map((result) => {
                return plainToClass(D365RetrieveUpsertCase, result, { excludeExtraneousValues: true });
            }),
        );
    }

    /**
     * @description In base alla request permette di Creare, Modificare, Risolvere o Cancellare un case
     */
    upsertCase(upsertCaseReq: UpsertCaseReq): Observable<D365CaseCloseResponse> {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: { JsonData: JSON.stringify(upsertCaseReq) },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_upsertcase',
                    parameterTypes: {
                        JsonData: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };
        return this.asyncPostMessage<D365CaseCloseResponse>(message).pipe(
            map((res: D365CaseCloseResponse) => {
                return res;
            }),
            tap((res: D365CaseCloseResponse) => {
                if (res?.APIResult !== 'OK') {
                    throw new Error(res?.APIResultDetail || this.genericApiError);
                }
            }),
            catchError((error: Error) => {
                this.logger.error('Errore chiamata gestione case', error?.message, error, true);
                return of(null);
            }),
        );
    }

    /**
     * @description Restituisce i case aperti in base alla quote
     */
    retrieveUpsertCaseQuote(params: FetchXmlParams): Observable<D365RetrieveUpsertCase> {
        const { entity, fetchxml } = getFetchXmlQuery(CASE_DATA_BY_QUOTE, params);
        const message: D365PostMessage = {
            type: 'retrievemultiplefetch',
            content: {
                entity,
                fetchxml,
            },
        };
        return this.asyncPostMessage<{ queryresult: {} }>(message).pipe(
            map((response) => {
                if (!response) {
                    throw new Error('Impossibile recuperare i case associati alla quote');
                }
                return response?.queryresult || {};
            }),
            map((result) => {
                return plainToClass(D365RetrieveUpsertCase, result, { excludeExtraneousValues: true });
            }),
            catchError((err) => {
                this.logger.error(
                    `Case Error`,
                    `Impossibile recuperare i case associati alla quote: ${params}`,
                    err,
                    true,
                );
                return of(null);
            }),
        );
    }

    /**
     * @description Recupera i dati del workorder di servizio (prodotti con appuntamento: manutenzione, assicurazione)
     */
    retrieveWorkOrderData(params: FetchXmlParams): Observable<D365WorkOrderServiceData[]> {
        const { entity, fetchxml } = getFetchXmlQuery(GET_WORK_ORDER_DATA, params);
        const message: D365PostMessage = {
            type: 'retrievemultiplefetch',
            content: {
                entity,
                fetchxml,
            },
        };
        return this.asyncPostMessage<{ queryresult: [{}] }>(message).pipe(
            map((response) => response?.queryresult || [{}]),
            map((result) => {
                return plainToClass(D365WorkOrderServiceData, result, { excludeExtraneousValues: true });
            }),
        );
    }

    retrieveSerialNumber(assetExternalId: string): Observable<SupSerialNumber[]> {
        return this.asyncPostMessage<{ queryresult: D365SerialNumber[] }>(
            this.getMessageFromXmlQuery(getSerialNumberXmlQuery(assetExternalId)),
        ).pipe(
            map((response) => response?.queryresult ?? []),
            map((serialNumber) =>
                serialNumber.map((serialNumber) => mapFields(serialNumber, D365ToSupSerialNumberFieldMap)),
            ),
        );
    }

    /**
     * @description Recupera i dati del workorder logistici (prodotti con spedizione: smarthome)
     */
    retrieveWorkOrderLogisticData(params: FetchXmlParams): Observable<D365WorkOrderLogisticData[]> {
        const { entity, fetchxml } = getFetchXmlQuery(GET_WORK_ORDER_LOGISTIC, params);
        const message: D365PostMessage = {
            type: 'retrievemultiplefetch',
            content: {
                entity,
                fetchxml,
            },
        };
        return this.asyncPostMessage<{ queryresult: [{}] }>(message).pipe(
            map((response) => response?.queryresult || [{}]),
            map((result) => {
                return plainToClass(D365WorkOrderLogisticData, result, { excludeExtraneousValues: true });
            }),
        );
    }

    retrieveWorkOrderStoricoCase(params: FetchXmlParams): Observable<D365WorkOrderCaseData[]> {
        const { entity, fetchxml } = getFetchXmlQuery(GET_WORK_ORDER_CASE, params);
        const message: D365PostMessage = {
            type: 'retrievemultiplefetch',
            content: {
                entity,
                fetchxml,
            },
        };
        return this.asyncPostMessage<{ queryresult: [{}] }>(message).pipe(
            map((response) => response?.queryresult || [{}]),
            map((result) => {
                return plainToClass(D365WorkOrderCaseData, result, { excludeExtraneousValues: true });
            }),
        );
    }

    retrieveESEAgencyInfo(partnerName: string): Observable<AgencyESEInfo[]> {
        return this.asyncPostMessage<{ queryresult: D365AgencyESEInfo[] }>(
            this.getMessageFromXmlQuery(getAgencyESEXmlQuery(partnerName)),
        ).pipe(
            map((response) => response?.queryresult ?? []),
            map((workOrders) => workOrders.map((wo) => mapFields(wo, D365ToAgencyESEFieldMap))),
            catchError((error) => {
                throw Error(error);
            }),
        );
    }

    /**
     * @description Recupera i dati del work order di credito al consumo
     */
    retrieveWorkOrderConsumerCredit(assetExternalId: string): Observable<SupConsumerCreditWorkOrder[]> {
        return this.asyncPostMessage<{ queryresult: D365ConsumerCreditWorkOrder[] }>(
            this.getMessageFromXmlQuery(getConsumerCreditWorkOrderXmlQuery(assetExternalId)),
        ).pipe(
            map((response) => response?.queryresult ?? []),
            map((workOrders) => workOrders.map((wo) => mapFields(wo, D365ToSupConsumerCreditWorkOrderFieldMap))),
        );
    }

    /**
     * @description Recupera i dati del work order di credito al consumo
     */
    retrieveWorkOrderDocumentaryCheck(assetExternalId: string): Observable<SupDocumentaryCheckWorkOrder[]> {
        return this.asyncPostMessage<{ queryresult: D365DocumentaryCheckWorkOrder[] }>(
            this.getMessageFromXmlQuery(getDocumentaryCheckWorkOrderXmlQuery(assetExternalId)),
        ).pipe(
            map((response) => response?.queryresult ?? []),
            map((workOrders) => workOrders.map((wo) => mapFields(wo, D365ToSupDocumentaryCheckWorkOrderFieldMap))),
        );
    }

    retrieveOver75Range(): Observable<D365Over75Data> {
        const { entity, fetchxml } = getFetchXmlQuery(GET_OVER75_RANGE);
        const message: D365PostMessage = {
            type: 'retrievemultiplefetch',
            content: {
                entity,
                fetchxml,
            },
        };
        return this.asyncPostMessage<{ queryresult: {} }>(message).pipe(
            map((response) => response?.queryresult || {}),
            map((result) => {
                return plainToClass(D365Over75Data, result, { excludeExtraneousValues: true });
            }),
        );
    }

    getOauth2AccessToken(): Observable<Oauth2AccessToken> {
        return this.asyncPostMessage<Oauth2AccessToken>(
            {
                type: 'executeaction',
                content: {
                    request: {},
                    requestmetadata: {
                        boundParameter: null,
                        operationType: 0,
                        operationName: 'egl_salesupauthorization_retrieveoauthtoken',
                        parameterTypes: {},
                    },
                },
            },
            'AuthenticationToken',
        ).pipe(
            tap((response) => {
                if (!response?.token || !response?.user) {
                    throw new Error('Error getting Access Token');
                }
            }),
            catchError((err) => {
                this.logger.error(err?.message);
                return of(null);
            }),
        );
    }

    /**
     * Apre Siebel in una nuova pagina del browser (effettua anche il login)
     * @returns
     */
    openSiebel(): Observable<void> {
        return this.asyncPostMessage(<D365PostMessage>{
            type: 'openSiebel',
        });
    }

    openAtecoPanel(searchValue?: string): Observable<{
        ateco: string;
        atecoDescription: string;
        activity: string;
        sector: string;
    }> {
        return from(
            this.openLookupObject(
                'egl_ateco',
                ['egl_name', 'egl_code', 'egl_productactivity', 'egl_productsector', 'egl_enabled'],
                false,
                null,
                `*${searchValue || ''}`,
                [ATECO_CODES_QUERY],
            ),
        ).pipe(
            map((result) =>
                result?.lookupresult?.egl_code
                    ? {
                          activity: result?.lookupresult?.egl_productactivity,
                          sector: result?.lookupresult?.egl_productsector,
                          atecoDescription: result?.lookupresult?.egl_name,
                          ateco: result?.lookupresult?.egl_code,
                      }
                    : null,
            ),
            catchError(() => of(null)),
        );
    }

    getD365ResidentialAddressByAccountNumbers(accountNumbers: string[]): Observable<D365AssetRoleByAccounts[]> {
        return this.asyncPostMessage<{ queryresult: [] }>(<D365PostMessage>{
            type: 'retrievemultiplefetch',
            content: {
                entity: 'egl_address_role',
                fetchxml: getD365ResidentialAddressByAccountNumber(accountNumbers).query,
            },
        }).pipe(
            map(
                (response) =>
                    response?.queryresult?.map(
                        (addressData) =>
                            <D365AssetRoleByAccounts>{
                                accountNumber: addressData['account.accountnumber'] || '',
                                fullAddress: addressData['address.egl_fulladdress'] || '',
                                egl_validfrom: addressData['egl_validfrom'],
                                egl_validto: addressData['egl_validto'],
                            },
                    ) || [],
            ),
        );
    }

    /**
     * Apre una web resource in una nuova tab https://learn.microsoft.com/en-us/power-apps/developer/model-driven-apps/clientapi/reference/xrm-navigation/openwebresource
     * @param webResourceName nome della webresource da aprire
     * @param windowOptions opzionale, larghezza e altezza della webresource
     * @param data dati opzionali da passare in forma di stringa
     * @returns
     */
    openWebResourceInNewWindow(
        webResourceName: string,
        windowOptions?: { width: number; height: number },
        data?: string,
    ): Observable<unknown> {
        return this.asyncPostMessage(<D365PostMessage>{
            type: 'openWebResourceNewWindow',
            content: JSON.stringify({
                webResourceName: webResourceName,
                windowOptions: windowOptions || {},
                data: data || '',
            }),
        });
    }

    navigateToOrApmNavigation(
        templateName: D365TemplateName,
        appContext: OmniChannelAppContext,
        isFocused: boolean = true,
        navigateToRequest: NavigateToRequest,
    ): Observable<void> {
        const content = {
            omnichannelTab: {
                templateName: templateName,
                appContext: appContext,
                isFocused: isFocused,
            },
            navigateToRequest: navigateToRequest,
        };
        const message = {
            type: 'navigateToOrApmNavigation',
            content: JSON.stringify(content),
        };
        return this.asyncPostMessage(message);
    }

    getEnabledOperativeModesByProfile(): Observable<string[]> {
        if (this.configSrv.get<boolean>('showAllOperativeModesOnFilter', false)) {
            return of([]);
        }

        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: {},
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_userprofile_getoperativemodesbyuser',
                    parameterTypes: {},
                },
            },
        };

        return this.asyncPostMessage(message).pipe(
            map((result) => result as OperativeModesByProfile),
            tap((operativeModesByProfile) => {
                if (operativeModesByProfile?.Result == 'KO') {
                    throw new Error(
                        operativeModesByProfile?.ErrorMessage || 'Error  D365 getEnabledOperativeModesByProfile',
                    );
                }
            }),
            map((result) => JSON.parse(result.EnabledOperativeModes) || []),
            catchError((err) => {
                console.error(err);
                return of(null);
            }),
        );
    }

    openSignatureApp(codicePlico: string, timeDelay: number = 1000) {
        const grafoSignAppLink = this.configSrv.config.grafoSignApp;
        return of(null).pipe(
            delay(timeDelay),
            take(1),
            mergeMap(() => {
                const openAppCmd = grafoSignAppLink.replace('{contractCode}', codicePlico);
                this.logger.debug(
                    "Provo ad aprire l' applicazione della firma grafometrica tramite topNavigationRequest D365",
                    openAppCmd,
                );
                return this.topNavigationRequest(openAppCmd);
            }),
        );
    }

    /**
     * Invia DOI per cambio titolarità cliente conferma cliente al cliente
     * @param request oggetto di tipo DataOwnershipChangeRequest
     * @returns Observable<DataOwnershipChangeResponse> ritorna l'id del record creato
     */
    sendDataOwnershipChange(request: DataOwnershipChangeRequest): Observable<DataOwnershipChangeResponse> {
        let translateSrv = this.injector.get(TranslateService);
        LoadingService.show(translateSrv.instant(`ORDER_ENTRY.DATA_OWNERSHIP_CHANGE.LOADER.DOI_SEND`));

        return this.asyncPostMessage<any>({
            type: 'executeaction',
            content: {
                request: { DataOwnershipChangeRequest: JSON.stringify(request) },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_dataownershipchange_createandsend',
                    parameterTypes: {
                        DataOwnershipChangeRequest: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        }).pipe(
            map((response) => response),
            tap((response) => {
                LoadingService.hide();
                if (!response || !response?.Result) {
                    throw new Error(translateSrv.instant(`ORDER_ENTRY.DATA_OWNERSHIP_CHANGE.ERRORS.DOI_SEND`));
                }

                if (response?.Result == 'KO') {
                    throw new Error(response?.ErrorMessage);
                }
            }),
            catchError((err) => {
                this.logger.error(translateSrv.instant(`ORDER_ENTRY.DATA_OWNERSHIP_CHANGE.ERRORS.DOI_RESEND`));
                return of(null);
            }),
        );
    }

    /**
     * Richiama la custom Api di esitazione del cambio titolarità cliente,
     * @param request oggetto di tipo DataOwnershipChangeOutcomeRequest
     * @returns Observable<DataOwnershipChangeOutcomeResponse>
     */
    createDataOwnershipChangeOutcome(
        request: DataOwnershipChangeOutcomeRequest,
    ): Observable<DataOwnershipChangeOutcomeResponse> {
        let translateSrv = this.injector.get(TranslateService);
        LoadingService.show(translateSrv.instant(`ORDER_ENTRY.DATA_OWNERSHIP_CHANGE.LOADER.DOI_RESEND`));

        return this.asyncPostMessage<any>({
            type: 'executeaction',
            content: {
                request: { CreateOutcomeRequest: JSON.stringify(request) },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_dataownershipchange_createoutcome',
                    parameterTypes: {
                        CreateOutcomeRequest: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        }).pipe(
            map((response) => response),
            tap((response) => {
                LoadingService.hide();
                if (!response || !response?.Result) {
                    throw new Error(translateSrv.instant(`ORDER_ENTRY.DATA_OWNERSHIP_CHANGE.ERRORS.DOI_RESEND`));
                }

                if (response?.Result == 'KO') {
                    throw new Error(response?.ErrorMessage);
                }

                return of(true);
            }),
            catchError((err) => {
                this.logger.error(
                    translateSrv.instant(`ORDER_ENTRY.DATA_OWNERSHIP_CHANGE.ERRORS.DOI_RESEND`),
                    err?.message,
                    '',
                    true,
                );
                return of(null);
            }),
        );
    }

    uploadInstanceForm(req: any, caseNumber?: string, quote?: QuoteDetail): Observable<string> {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: { JsonData: JSON.stringify(req) },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_upsertemail',
                    parameterTypes: {
                        JsonData: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };
        return this.asyncPostMessage<any>(message).pipe(
            // tap((res: any) => {
            //     if (res?.APIResult !== 'OK') {
            //         this.logger.error(
            //             'Errore',
            //             'Errore nella condivisione del modulo di istanza nel case',
            //             res?.APIResultDetail,
            //             false
            //         );
            //         throw new Error('Errore nella condivisione del modulo di istanza nel case');
            //     }
            // }),
            mergeMap((res: any) => {
                if (!res || res?.APIResult !== 'Ok') {
                    const rollBackCaseReq: UpsertCaseReq = {
                        customer: {
                            customerType: 'ACCOUNT',
                            customerCode: quote.customerCode,
                        },
                        caseNumber,
                        channel: '2',
                        caseCharacteristic: '281820000',
                        caseLevel1Code: 'CASLEV-0000044',
                        caseLevel2Code: 'CASLEV-0000654',
                        caseLevel3Code: 'CASLEV-0000663',
                        caseOrigin: 'CLIENTE',
                        quoteCode: quote.name,
                        status: '6',
                        processCode: '001',
                    };
                    return this.upsertCase(rollBackCaseReq);
                }
                return res;
            }),
            catchError((error: Error) => {
                this.logger.error('Errore chiamata gestione case', error?.message, error, true);
                return of(null);
            }),
            map((res: D365CaseCloseResponse) => {
                return res?.APIResultDetail;
            }),
        );
    }

    /**
     * @description Restituisce tutte le consociate rispetto ad un ese id
     */
    retrieveSubsidiaryByAgencyId(params: FetchXmlParams): Observable<AgencyBranchForSubsidiary[]> {
        const { entity, fetchxml } = getFetchXmlQuery(SUBSIDIARY_BY_AGENCY_ID, params);
        const message: D365PostMessage = {
            type: 'retrievemultiplefetch',
            content: {
                entity,
                fetchxml,
            },
        };
        return this.asyncPostMessage<{ queryresult: [] }>(message).pipe(
            map((response) => response?.queryresult || []),
            map((results) =>
                results.map((incident) =>
                    plainToClass(AgencyBranchForSubsidiary, incident, { excludeExtraneousValues: true }),
                ),
            ),
        );
    }

    /**
     * @description Recupera le informazioni dell'ESE per i prodotti complex
     */
    retrieveAgencyEseComplex(params: FetchXmlParams): Observable<AgencyEseData[]> {
        const { entity, fetchxml } = getFetchXmlQuery(AGENCY_ESE_COMPLEX, params);
        const message: D365PostMessage = {
            type: 'retrievemultiplefetch',
            content: {
                entity,
                fetchxml,
            },
        };
        return this.asyncPostMessage<{ queryresult: [] }>(message).pipe(
            map((response) => response?.queryresult || []),
            map((results) =>
                results.map((incident) => plainToClass(AgencyEseData, incident, { excludeExtraneousValues: true })),
            ),
        );
    }

    private getMessageFromXmlQuery(xmlRequest: FetchXmlQuery, type: string = 'retrievemultiplefetch'): D365PostMessage {
        return {
            type,
            content: {
                entity: xmlRequest.entity,
                fetchxml: singleLineString(xmlRequest.query),
            },
        };
    }

    getcaringlightconfiguration(): Observable<CaringLightConfiguration> {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: {},
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_user_getcaringlightconfiguration',
                    parameterTypes: {},
                },
            },
        };

        return this.asyncPostMessage<CaringLightConfiguration>(message, 'CaringLightConfig').pipe(
            tap((config) => console.log('Received configuration:', config)), // Logging
            map((config) => {
                let parsedUserConfig: UserCaringLightConfiguration;

                try {
                    parsedUserConfig =
                        typeof config.UserCaringLightConfiguration === 'string'
                            ? JSON.parse(config.UserCaringLightConfiguration)
                            : config.UserCaringLightConfiguration;
                } catch (error) {
                    console.error('Error parsing UserCaringLightConfiguration:', error);
                    parsedUserConfig = {} as UserCaringLightConfiguration; // Fallback in caso di errore
                }

                const typedConfig: CaringLightConfiguration = {
                    ErrorMessage: config?.ErrorMessage,
                    UserCaringLightConfiguration: parsedUserConfig,
                    Result: config.Result,
                };

                return typedConfig;
            }),
        );
    }

    createRecordAsync(entity: string, data: any, responseType?: string): Observable<CreateRecordResponse> {
        const type = (responseType ? responseType : `${entity}response`) + getRandomHash();
        const message: D365PostMessage = {
            type: 'createrecordrequest',
            content: {
                entity,
                data,
                type,
            },
        };
        return this.asyncPostMessage(message);
    }

    openMultipleLookupObject(
        entity: Array<string>,
        multiSelect: boolean = false,
        viewId?: string[],
        searchText: string = '',
        lookupFilters?: LookupFilter[],
    ): Promise<any> {
        if (!viewId) {
            viewId = ['00000000-0000-0000-0000-000000000000'];
        }

        const message: D365PostMessage = {
            type: 'openmultiplelookupobject',
            content: {
                entity,
                multiselect: multiSelect,
                viewid: viewId,
                searchtext: searchText,
                filters: lookupFilters,
            },
        };

        return this.asyncPostMessage(message, null, 300000).toPromise();
    }

    openCustomerPanel(searchValue?: string): Observable<Array<MultipleLookupObjectResponse>> {
        return from(this.openMultipleLookupObject(['account'], false, null, `${searchValue || ''}`)).pipe(
            map((result) => result.lookupresult.results),
            catchError(() => of(null)),
        );
    }

    upsertCaseCaring(upsertCaseReq: UpsertCaseCaringReq): Observable<D365CaseCloseResponse> {
        const message: D365PostMessage = {
            type: 'executeaction',
            content: {
                request: { JsonData: JSON.stringify(upsertCaseReq) },
                requestmetadata: {
                    boundParameter: null,
                    operationType: 0,
                    operationName: 'egl_upsertcase',
                    parameterTypes: {
                        JsonData: { typeName: 'Edm.String', structuralProperty: 1 },
                    },
                },
            },
        };
        return this.asyncPostMessage<D365CaseCloseResponse>(message).pipe(
            map((res: D365CaseCloseResponse) => {
                return res;
            }),
            tap((res) => {
                if (res?.APIResult !== 'OK') {
                    throw new Error(res?.message || this.genericApiError);
                }
            }),
            catchError((error: Error) => {
                this.logger.error('Errore', error?.message, error, true);
                return of(null);
            }),
            finalize(() => {
                LoadingService.hide();
            }),
        );
    }

    getPhoneCall(agent: string): Observable<string> {
        return this.retrieveMultipleRecordsFetchAsync(getPhoneCallQuery(agent)).pipe(
            map((results) => ((results || [])[0] || ({} as any)).softphon_iwsinteractionid),
        );
    }

    /**
     * @description Restituisce l'assetIntegrationId di un assetId
     */
    retrieveAssetIntegrationId(params: FetchXmlParams): Observable<AssetIntegrationIdData[]> {
        const { entity, fetchxml } = getFetchXmlQuery(ASSET_INTEGRATION_ID_BY_ASSET_ID, params);
        const message: D365PostMessage = {
            type: 'retrievemultiplefetch',
            content: {
                entity,
                fetchxml,
            },
        };
        return this.asyncPostMessage<{ queryresult: [] }>(message).pipe(
            map((response) => response?.queryresult || []),
            map((results) =>
                results.map((assetIntegrationId) =>
                    plainToClass(AssetIntegrationIdData, assetIntegrationId, { excludeExtraneousValues: true }),
                ),
            ),
        );
    } 

    retrieveCutOffDate(effDate: string): Observable<SupCutOffDate[]> {
        return this.asyncPostMessage<{ queryresult: D365CutOffDate[] }>(
            this.getMessageFromXmlQuery(getCutOffDateXmlQuery(effDate)),
        ).pipe(
            map((response) => response?.queryresult ?? []),
            map((cutOffDate) => cutOffDate.map((cutOffDate) => mapFields(cutOffDate, D365ToSupCutOffDateFieldMap))),
        );
    }

    /**
     * @description Recupero public configuration
     */
    getPublicConfiguration(egl_key: string): Promise<string> {
        const filter = `egl_key eq '${egl_key}'`;
        return this.retrieveMultipleRecordsAsync('egl_publicconfiguration', filter, ['egl_value'])
            .pipe(
                take(1),
                map((results: { egl_value: string }[]) => (results.length != 0 ? results[0].egl_value : null)),
            )
            .toPromise();
    }

    async getMunicipalityPODTransferConf(): Promise<MuicipalityPodConfiguration> {
        const response = await this.getPublicConfiguration('MunicipalityPODTransferBlock.FeatureFlag');

        const parsedData = JSON.parse(response);

        return new MuicipalityPodConfiguration({
            TransferBlockFeatureFlag: parsedData.TransferBlockFeatureFlag,
            PODWarningFeatureFlag: parsedData.PODWarningFeatureFlag,
            Municipalities: parsedData.Municipalities?.map((m: any) => new Municipality(m)) || [],
            OperativeMode: parsedData.OperativeMode,
        });
    } 

}
