import { AptSalesProcess } from '../../../common/enums/apttus/apt-sales-process';
import { BoFilterByDate } from './bo-filter-by-date';
import { BoSearchByField } from './bo-search-by-field';

export class CarrelliFilterList {
    date?: BoFilterByDate;
    orderBy?: string;
    seeAll?: boolean;
    operativeMode?: AptSalesProcess;
    searchByField?: BoSearchByField;
    constructor(applyDefault?: boolean) {
        this.date = new BoFilterByDate(applyDefault);
    }
}
