import { Regex } from '../../../../config/regex';
import { AttributeValues } from './base-attribute-values';

export class InsuranceAttributeValues extends AttributeValues {
    attributesMapping(): void {
        super.attributesMapping();

        this.attr['egl_Floor__c'] = this.currentProduct?.deliveryAddress?.floor;
        this.attr['egl_salesrep_organizationalunit__c'] = this.currentProduct?.agencyBranch;
        this.attr['egl_iscontextualsale__c'] = this.currentProduct?.isCombinedSale;

        if (this.currentProduct?.podPdr) {
            this.attr[this.getPowerOrGasAttr(this.currentProduct?.podPdr)] = this.currentProduct?.podPdr;
        }
    }

    // metodo che restituisce l'attribute da mandare a OMF tramite Regex pod o pdr (in caso nel current product non ci sia 'powerOrGas')
    getPowerOrGasAttr(podPdr: string): string {
        if (Regex.POD.test(podPdr)) {
            return 'egl_POD__c';
        }
        if (Regex.PDR.test(podPdr)) {
            return 'egl_PDR__c';
        }
    }
}
