import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { isEmpty as _isEmpty } from 'lodash';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, filter, isEmpty, map, mergeMap, retry, take, tap } from 'rxjs/operators';
import { Regex } from '../../../../common/config/regex';
import { AptCodeType, AptCommodityType } from '../../../../common/enums/apttus/apt-commodity-typeof-sale';
import { AptCustomerType } from '../../../../common/enums/apttus/apt-customer-type';
import { AptSalesProcess } from '../../../../common/enums/apttus/apt-sales-process';
import { parseToDate } from '../../../../common/functions/misc.functions';
import { aptSalesProcessToFlowType } from '../../../../common/functions/remap.functions';
import { addressToIstatCode } from '../../../../common/functions/string-format.functions';
import { flowTypeUtil } from '../../../../common/functions/verifications.functions';
import { BaseApexApiResponse } from '../../../../common/interfaces/base-apex-api-respose';
import { BaseApiResponse, StatusResponse } from '../../../../common/interfaces/base-api-response';
import { ProductsAffinityForComuneIstatResponse } from '../../../../common/models/app/products-affinity-for-comune-istat-response';
import { ServiceError } from '../../../../common/models/app/service-error';
import { OkKo, YesNo } from '../../../../common/models/app/si-no-type';
import { WorkOrderAPIMRawRequest, WorkOrderAPIMRequest } from '../../../../common/models/apttus/work-order.model';
import { ApexApi, ApiMngApi, ApttusApi, BaseProvider } from '../../../../common/providers/base-provider';
import { ApiService } from '../../../../common/services/shared/api.service';
import { FeatureToggleService } from '../../../../common/services/shared/feature-toggle.service';
import { NO_LOADING } from '../../../../common/services/shared/http-request-interceptor.service';
import { LoggerService } from '../../../../common/services/shared/logger.service';
import { PrivateConfigurationService } from '../../../../common/services/shared/private-configuration.service';
import { UtilityService } from '../../../../common/services/shared/utility.service';
import { FlowType, MacroFlowType } from '../../../../store/models/flow-type';
import { PdrActivationInfo, PdrInfoLocation, PodActivationInfo } from '../../../../store/models/order-entry-state';
import { Address } from '../../../../store/models/order-entry-state_v2';
import { EglState } from '../../../../store/reducers';
import { v2SelectDistinctCommodities, v2SelectFlowType } from '../../../../store/selectors/order-entry-v2.selectors';
import { CheckCapResponse } from '../../../switch-in/order-entry/models/check-cap-response';
import { CheckPdfApttusRequest } from '../../../switch-in/order-entry/models/check-pdf-apttus-request';
import {
    CheckPdfApttusResponse,
    CheckPdfApttusResponseResult,
} from '../../../switch-in/order-entry/models/check-pdf-apttus-response';
import { CheckPdfResponse, EsitoResponse } from '../../../switch-in/order-entry/models/check-pdf-response';
import {
    ActStatus,
    AddressActResponse,
    CheckPodPdrActivationResponse,
} from '../../../switch-in/order-entry/models/check-pod-pdr-activation-response';
import { Indirizzo } from '../../../switch-in/order-entry/models/indirizzi';
import { OcrData } from '../../../switch-in/order-entry/models/ocr-data';
import { OcrFeedbackRequest } from '../../../switch-in/order-entry/models/ocr-feedback-request';
import { CreaPraticaResponse, CreaPraticaResponseRaw } from '../../../switch-in/technical-changes/models/crea-pratica';
import {
    InfoDistributoreRequest,
    InfoDistributoreResponse,
} from '../../../switch-in/technical-changes/models/info-distributore';
import {
    RetrieveQuotationCostsRequest,
    RetrieveQuotationCostsResponse,
} from '../../../switch-in/technical-changes/models/retrieve-quotation-costs';
import {
    SupplyAddressRequest,
    SupplyAddressResponse,
} from '../../../switch-in/technical-changes/models/supply-address';
import {
    ProductCostsRequest,
    ProductCostsRequestApim,
} from '../../../termination/order-entry/models/product-costs-request';
import {
    ProductCostsResponse,
    ProductCostsResponseApim,
} from '../../../termination/order-entry/models/product-costs-response';
import { ConsumptionValidationRequest } from '../../../transfer/models/consumption-validation-request';
import { ConsumptionValidationResponse } from '../../../transfer/models/consumption-validation-response';
import { DEFAULT_GAS_METER_CLASS, DEFAULT_GAS_POTENTIAL } from '../../default.values';
import { ApttusQueryResponse, QueryResponseType } from '../models/apttus-query-response';
import { CheckCartDualResponse } from '../models/cart-dual-response';
import { CommodityForCombinedSaleResponse } from '../models/check-commodity-response';
import { CheckInsurancePodPdrResponse, CheckInsuranceTaxCodeResponse } from '../models/check-insurance.models';
import { CreateQuoteEntitiesResponse } from '../models/create-quote-entities-response';
import { DefaultGasActivationPotential } from '../models/default-gas-activation-potential-response';
import { PaperCheckResponse } from '../models/paper-check-response';
import { RataFissaResponse } from '../models/rata-fissa-response';
import { RemoteMeterCheckResponse } from '../models/remote-meter-check.response';
import { RetrieveSerialNumeberResponse } from '../models/retrieve-serial-number.response';
import { SelfCertificationJsonRequest } from '../steps/06-firma/models/self-certification';
import { UploadIdentityCardRequest } from '../steps/06-firma/models/upload-identity-card.request';

@Injectable({
    providedIn: 'root',
})
export class OrderEntryProvider extends BaseProvider {
    constructor(
        private api: ApiService,
        private utilitySrv: UtilityService,
        private logger: LoggerService,
        protected configSrv: PrivateConfigurationService,
        private store: Store<EglState>,
        private toggleService: FeatureToggleService,
        translateSrv: TranslateService,
    ) {
        super(configSrv, translateSrv);
    }

    ocrInvia(files: File[]): Observable<BaseApiResponse<OcrData>> {
        const formData = new FormData();
        files?.forEach((x) => formData.append('files', x));

        return this.api.postAsync<BaseApiResponse<OcrData>>(this.getApiMngApiUrl(ApiMngApi.OcrInvia), formData).pipe(
            catchError(() => {
                return of(null);
            }),
        );
    }

    ocrFeedback(req: OcrFeedbackRequest): Observable<BaseApiResponse> {
        return this.api.postAsync<BaseApiResponse>(this.getApiMngApiUrl(ApiMngApi.OcrFeedback), req).pipe(
            tap((res) => {
                if (res?.status !== StatusResponse.Success) {
                    throw new Error('status not in SUCCESS in OCR feedback api');
                }
            }),
        );
    }

    uploadIdentityCardOrAttach(req: Partial<UploadIdentityCardRequest>): Observable<BaseApiResponse> {
        const jBody = {
            FileNameUnico: '',
            FileNameFronte: '',
            FileNameRetro: '',
        };
        const formData = new FormData();
        if (req.Fronte && req.Retro) {
            const tmpFronte = this.utilitySrv.renameFile(req.Fronte, `${req.NumeroPlico}_F`, true);
            const tmpRetro = this.utilitySrv.renameFile(req.Retro, `${req.NumeroPlico}_R`, true);
            formData.append('Fronte', tmpFronte);
            formData.append('Retro', tmpRetro);
            jBody.FileNameFronte = tmpFronte.name;
            jBody.FileNameRetro = tmpRetro.name;
        } else {
            const tmpFile = this.utilitySrv.renameFile(req.Unico, `${req.NumeroPlico}_CI`, true);
            formData.append('Unico', tmpFile);
            jBody.FileNameUnico = tmpFile.name;
        }
        formData.append('CartID', req.CartID);
        formData.append('jsonBody', JSON.stringify(jBody));
        return this.api.postAsync<BaseApiResponse>(this.getApiMngApiUrl(ApiMngApi.UploadIdentityCard), formData);
    }

    /**
     * @description: invoca ApiManagement per il check sul Punto di Fornitura
     * @param tipoFornitura: 'Power' | 'Gas'
     * @param podPdr: numero di POD o PDR
     */
    checkPdf(tipoFornitura: AptCommodityType, podPdr: string): Observable<void> {
        const COMMODITY_TYPE_CODE_MAP: {
            [key in AptCommodityType]: 'POD' | 'PDR';
        } = {
            [AptCommodityType.Power]: 'POD',
            [AptCommodityType.Gas]: 'PDR',
        };
        return this.api
            .postAsync<BaseApiResponse<CheckPdfResponse>>(this.getApiMngApiUrl(ApiMngApi.CheckPdf), {
                codicePdrPod: podPdr.toUpperCase(),
                tipoFornitura,
            })
            .pipe(
                tap((res) => {
                    if (res?.status !== StatusResponse.Success || res?.response?.esito !== EsitoResponse.OK) {
                        this.logger.warn(
                            `ATTENZIONE! - Il ${COMMODITY_TYPE_CODE_MAP[tipoFornitura]} inserito non risulta disponibile`,
                        );
                        const message = res?.response?.modalitaOperativa
                            ? 'ORDER_ENTRY.PDF_MODAL.MESSAGE'
                            : res?.response?.descrizioneEsito || res?.response
                              ? 'ORDER_ENTRY.PDF_MODAL.ERROR_BUSY'
                              : 'ORDER_ENTRY.PDF_MODAL.ERROR_CALL';
                        throw new CheckError(
                            COMMODITY_TYPE_CODE_MAP[tipoFornitura].toLowerCase() as Lowercase<
                                (typeof COMMODITY_TYPE_CODE_MAP)[AptCommodityType]
                            >,
                            this.translateSrv.instant(message, {
                                codeType: COMMODITY_TYPE_CODE_MAP[tipoFornitura],
                                operationMode: res?.response?.modalitaOperativa,
                            }),
                        );
                    }
                }),
                map(() => null),
                catchError((err: Error) => {
                    throw new CheckError(
                        COMMODITY_TYPE_CODE_MAP[tipoFornitura].toLowerCase() as Lowercase<
                            (typeof COMMODITY_TYPE_CODE_MAP)[AptCommodityType]
                        >,
                        err,
                    );
                }),
            );
    }

    /**
     * @description: invoca Apttus per il check sulla Matrice di Prevalenza
     * @param salesProcess: salesProcess
     * @param podPdr: numero di POD o PDR
     */
    checkAsset(salesProcess: AptSalesProcess, podPdr: string, isWinBack?: boolean): Observable<void> {
        const req = new CheckPdfApttusRequest(podPdr.toUpperCase(), salesProcess);
        return this.api.postAsync<CheckPdfApttusResponse>(this.getApexApiUrl(ApexApi.CheckAsset), req).pipe(
            tap((res) => {
                if (res?.Result !== CheckPdfApttusResponseResult.Success) {
                    if (isWinBack && flowTypeUtil(aptSalesProcessToFlowType(salesProcess)).equalTo(FlowType.SwitchIn)) {
                        // Nel caso di POD/PDR winback con un solo processo e SWO in corso bypassiamo l'errore
                        const processes = res?.ListaProcessi || [];
                        if (processes.length === 1 && processes[0]?.salesProcess === AptSalesProcess.SwitchOut) {
                            return null;
                        }
                    }

                    const message =
                        res?.Result === CheckPdfApttusResponseResult.Failed
                            ? 'ORDER_ENTRY.PDF_MODAL.MESSAGE'
                            : res?.ErrorMessage || 'ORDER_ENTRY.PDF_MODAL.ERROR_CALL';
                    const codeType = Regex.POD.test(podPdr) ? 'POD' : Regex.PDR.test(podPdr) ? 'PDR' : 'POD/PDR';
                    throw new Error(
                        this.translateSrv.instant(message, {
                            codeType,
                            operationMode: salesProcess,
                        }),
                    );
                }
            }),
            map(() => null),
            catchError((err: Error) => {
                throw new CheckError('asset', err);
            }),
        );
    }

    /**
     * @description: invoca una APEX API che fa upsert di Account, Contact e AccountLocation
     * @param productconfigurationid: ID del cart
     * @return:  Observable<CreateQuoteEntitiesResponse>
     */
    createQuoteEntities(productconfigurationid: string): Observable<CreateQuoteEntitiesResponse> {
        return this.api
            .postAsync<CreateQuoteEntitiesResponse>(
                this.getApexApiUrl(ApexApi.CreateQuoteEntities),
                { productconfigurationid },
                undefined,
                NO_LOADING,
            )
            .pipe(
                map((r) => {
                    if (r.ErrorMessage) {
                        this.logger.error('createQuoteEntities', 'error: api/salesup/quote/v1', r.ErrorMessage);
                    }
                    return r;
                }),
            );
    }

    /**
     * @description: verifica se la modalità di firma cartacea sia compatibile con il carrello corrente
     * @param cartId: ID del cart
     * @return: PaperCheckResponse
     */
    paperCheck(productconfigurationid: string): Observable<PaperCheckResponse> {
        return this.api.getAsync<PaperCheckResponse>(this.getApexApiUrl(ApexApi.PaperCheck), {
            productconfigurationid,
        });
    }

    /**
     * @description Controlla la validità del codice POD/PDR fornito
     * @param code Il codice POD/PDR da controllare
     * @param codeType Il tipo del codice fornito (PDR => GAS \ POD => Power)
     * @param codiceAgente Codice dell'agente
     * @param quoteId (Opzionale) Id della quote
     * @returns
     */
    checkPodPdr(code: string, codeType: 'PDR' | 'POD', codiceAgente: string, quoteId?: string): Observable<void> {
        const request = {
            source: 'SALESUP',
            codiceAgente,
            quoteId,
        };
        request[codeType] = code;
        const codeApi = `Check${codeType}`;
        return this.api.postAsync<BaseApiResponse>(this.getApiMngApiUrl(ApiMngApi[codeApi]), request).pipe(
            tap((res) => {
                if (res?.status !== StatusResponse.Success) {
                    throw new Error(
                        res?.errorManagement?.errorDescription || `Errore nella chiamata al servizio ${codeApi}`,
                    );
                }
            }),
            map(() => null),
            catchError((err: Error) => {
                throw new CheckError(codeType.toLowerCase() as Lowercase<typeof codeType>, err);
            }),
        );
    }

    /**
     * @description Controlla la validità del codice POD/PDR fornito per Attivazione
     * @param code Il codice POD/PDR da controllare
     * @param codeType Il tipo del codice fornito (PDR => GAS \ POD => Power)
     * @param codiceAgente Codice dell'agente
     * @param quoteId (Opzionale) Id della quote
     * @param quoteId (Opzionale) Flow type corrente
     * @returns
     */
    checkPodPdrActivation(
        input: string | Indirizzo,
        codeType?: 'PDR' | 'POD' | 'MatrContatRich',
        codiceAgente?: string,
        flowType?: FlowType,
    ): Observable<{
        status: ActStatus;
        power: PodActivationInfo;
        gas: PdrActivationInfo & { pdrFromMeter: string };
        addressMeters?: PdrInfoLocation[];
        descErr: string;
    }> {
        const request = {
            operativeMode: flowType,
            source: 'SALESUP',
            codiceAgente,
            ...(typeof input === 'object'
                ? {
                      PrIndForn: input?.prov,
                      SiglaTopIndForn: input?.toponomastica,
                      CapIndForn: input?.cap,
                      IndForn: input?.via,
                      CivicoForn: input?.civico,
                      IstatComuneIndForn: `${input?.codiceIstatProvincia}${input?.codiceIstatComune}`,
                  }
                : {}),
        };
        const API_MAP: { [key in 'PDR' | 'POD' | 'MatrContatRich' | 'Address']: string } = {
            PDR: 'CheckPDRActivation',
            POD: 'CheckPODActivation',
            MatrContatRich: 'CheckPDRActivation',
            Address: 'CheckPDRActivation',
        };
        if (codeType && typeof input === 'string') {
            request[codeType] = input;
        }

        const codeApi = API_MAP[codeType || 'Address'];
        return this.api
            .postAsync<
                BaseApiResponse<CheckPodPdrActivationResponse>
            >(this.getApiMngApiUrl(ApiMngApi[codeApi]), request)
            .pipe(
                map(this.mapCheckPodPdrActivationStatus),
                tap(({ status, errorManagement }) => {
                    if (status !== StatusResponse.Success) {
                        throw new Error(errorManagement?.errorDescription);
                    }
                }),
                map(({ response }) => response),
                // Temporary fix GAS
                map(({ Stato, ...data }) => ({
                    ...data,
                    Stato: /inesistente/i.test(data?.interrogaPDRResponse?.EsitoRichiesta?.DesErrore)
                        ? ActStatus.Inesistente
                        : Stato,
                })),
                // PDR not found for given address
                map(({ Stato, interrogaIndirizzoResponse, ...data }) => ({
                    ...data,
                    interrogaIndirizzoResponse,
                    Stato:
                        (codeType || 'Address') === 'Address' && !interrogaIndirizzoResponse?.DatiPDR?.length
                            ? ActStatus.ErroreIndirizzo
                            : Stato,
                })),
                map(({ Stato, interrogaPODResponse, ...otherResponse }) => ({
                    status: Stato,
                    power: this.mapPodActivationInfo({
                        ...(interrogaPODResponse?.EsitoRichiesta?.DatiTecnici?.POD || {}),
                        ...(interrogaPODResponse?.EsitoRichiesta?.Fornitura || {}),
                        ...(interrogaPODResponse?.EsitoRichiesta?.IdentificativiRichiesta || {}),
                    }),
                    gas: this.mapPdrActivationInfo(otherResponse),
                    addressMeters: this.mapAddressMeters(otherResponse),
                    descErr: otherResponse?.interrogaPDRResponse?.EsitoRichiesta?.DesErrore,
                })),
                retry(2),
            );
    }

    private mapAddressMeters({ interrogaIndirizzoResponse }: { interrogaIndirizzoResponse?: AddressActResponse }) {
        return interrogaIndirizzoResponse?.DatiPDR;
    }

    private mapPodActivationInfo({
        TENSIONE,
        ULTIMA_POT_DISP,
        CAP,
        CIV,
        LOCALITA,
        INTERNO,
        ISTAT,
        PIANO,
        PROV,
        SCALA,
        TOPONIMO,
        UbicazioneFornituraPod,
        VIA,
        PIVA_DISTR,
    }: {
        [key: string]: string;
    }) {
        return {
            tensione: TENSIONE,
            ultimaPotDisp: ULTIMA_POT_DISP,
            fornitura: {
                cap: CAP,
                civ: CIV,
                interno: INTERNO,
                istat: ISTAT,
                localita: LOCALITA,
                piano: PIANO,
                prov: PROV,
                scala: SCALA,
                toponimo: TOPONIMO,
                ubicazioneFornituraPod: UbicazioneFornituraPod,
                via: VIA,
            },
            identificativiRichiesta: {
                pivaDistributore: PIVA_DISTR,
            },
        };
    }

    /**
     * @description Esegue la mappatura della response CheckPodPdrActivationResponse verso la struttura dati usata dal frontend
     * @param rawData La response dell'API con i dati grezzi
     * @returns PdrActivationInfo
     */
    private mapPdrActivationInfo(
        response: Partial<CheckPodPdrActivationResponse>,
    ): PdrActivationInfo & { pdrFromMeter: string } {
        const getFieldValue = (parentField: string, fieldName: string): any =>
            ['interrogaPDRResponse', 'interrogaMisuratoreResponse', 'interrogaIndirizzoResponse'].reduce(
                (result: any, container) =>
                    result ||
                    ([]
                        .concat(((response || {})[container] || {})[parentField])
                        .find((parent) => (parent || {})[fieldName]) || {})[fieldName],
                null,
            );

        return {
            pdrFromMeter: getFieldValue('DatiPDR', 'PDRDistrEsito'),
            meterNumber: getFieldValue('DatiMisuratore', 'MatrContEsito') || '',
            meterClass: getFieldValue('DatiMisuratore', 'ClasseContEsito') || DEFAULT_GAS_METER_CLASS,
            vendorCode: getFieldValue('DatiMessaggio', 'CodDistr'),
            potentiality: getFieldValue('DatiPDR', 'PotenzialitaPdrEsito') || DEFAULT_GAS_POTENTIAL,
            potentialityFromCheckPdr: !!getFieldValue('DatiPDR', 'PotenzialitaPdrEsito'),
            fornitura: response?.interrogaPDRResponse?.DatiPDR || new PdrInfoLocation(),
            statoContatore: getFieldValue('DatiMisuratore', 'StatoContEsito') || null,
        };
    }

    private mapCheckPodPdrActivationStatus(
        res: BaseApiResponse<CheckPodPdrActivationResponse>,
    ): BaseApiResponse<CheckPodPdrActivationResponse> {
        const ERROR_MAP = {
            '009': ActStatus.Attivo,
        };
        return {
            ...(res || ({} as BaseApiResponse<CheckPodPdrActivationResponse>)),
            response: {
                ...(res?.response || ({} as CheckPodPdrActivationResponse)),
                Stato: Object.values(ActStatus).includes(res?.response?.Stato)
                    ? res?.response?.Stato
                    : ERROR_MAP[res?.response?.interrogaPDRResponse?.EsitoRichiesta?.CodErrore] || ActStatus.Errore,
            },
        };
    }

    /**
     * @description Carica il file dell'autocertificazione
     * @param req Corpo della richiesta (contiene il tipo di autocertificazione, il codice plico e il quote id)
     * @param file Il file da caricare
     * @returns Promise<BaseApiResponse>
     */
    uploadSelfCertification(req: SelfCertificationJsonRequest, file: File): Observable<BaseApiResponse> {
        if (!req.CodicePlico || !file) {
            const res = new BaseApiResponse();
            res.status = StatusResponse.Failed;
            res.errorManagement = { errorDescription: 'Codice plico o file mancanti', errorCode: 'SUP001' };
            return of(res);
        }
        const formData = new FormData();
        const tmpFile = this.utilitySrv.renameFile(file, `${req.CodicePlico}_AC`, true);
        formData.append('fileToUpload', tmpFile);
        formData.append('jsonBody', JSON.stringify(req));
        return this.api.postAsync<BaseApiResponse>(this.getApiMngApiUrl(ApiMngApi.UploadSelfCertification), formData);
    }

    /**
     * @description Interroga apttus per verificare se la composizione del carrello sia dual
     * @param cartId id del carrello
     * @returns Promise<CheckCartDualResponse>
     */
    async checkCartDual(cartId: string): Promise<CheckCartDualResponse | null> {
        return await this.api
            .postAsync<CheckCartDualResponse>(this.getApexApiUrl(ApexApi.CheckCartDual), { cartId: cartId }, undefined)
            .pipe(
                catchError(() => of(null)),
                map((x: CheckCartDualResponse) => x),
            )
            .toPromise();
    }

    /**
     * @description Recupera i costi di cessazione da Neta
     * @param body object con i parametri della request
     * @returns Promise<TermCostsResponse>
     */
    productCostsRetrieve(body: ProductCostsRequest['Body']): Observable<ProductCostsResponse['Body']> {
        return this.api
            .postAsync<BaseApiResponse<ProductCostsResponse>>(this.getApiMngApiUrl(ApiMngApi.RetrieveQuotationCosts), {
                Header: {
                    Mittente: 'SUP',
                    TipoInterfaccia: 'REC_SPESE_SDR',
                    DataMessaggio: new Date(),
                    IsSimula: false,
                },
                Body: body,
            })
            .pipe(
                tap((res) => {
                    if (res?.status !== StatusResponse.Success || _isEmpty(res?.response?.Body)) {
                        throw new Error(res?.errorManagement?.errorDescription || 'Errore del servizio Net@');
                    }
                }),
                map((res) => res?.response?.Body),
            );
    }

    productCostsRetrieveApim(body: ProductCostsRequestApim): Observable<ProductCostsResponseApim> {
        return this.api
            .postAsync<
                BaseApiResponse<ProductCostsResponseApim>
            >(this.getApiMngApiUrl(ApiMngApi.RetrieveQuotationCostsApim), body)
            .pipe(
                tap((res) => {
                    if (res?.status !== StatusResponse.Success || _isEmpty(res?.response)) {
                        throw new Error(
                            res?.errorManagement?.errorDescription ||
                                'Errore del servizio recupero costi preventivo variazione potenza',
                        );
                    }
                }),
                map((res) => res?.response),
            );
    }

    /**
     * @description Recupera le commodity associate ad una quote di una vendita abbinata
     * @param insuranceProductCode product code della polizza
     * @param customerFiscalCode codice fiscale del cliente
     * @param commodityQuoteId id della quote
     * @param commodityType categoria della commodity
     * @returns Promise<CommodityForCombinedSaleResponse>
     */
    getCommodityForCombinedSale(
        insuranceProductCode: string,
        customerFiscalCode?: string,
        commodityQuoteId?: string,
        commodityType?: string,
    ): Observable<CommodityForCombinedSaleResponse> {
        return this.api.postAsync<CommodityForCombinedSaleResponse>(
            this.getApexApiUrl(ApexApi.CommodityForCombinedSale),
            {
                insuranceProductCode: insuranceProductCode,
                customerFiscalCode: customerFiscalCode,
                commodityQuoteId: commodityQuoteId,
                commodityType: commodityType,
            },
            undefined,
        );
    }

    /**
     * @description Recupera le forniture associate
     * @param fiscalCode codice fiscale del cliente
     * @param skuCombinedSales sku del prodotto assicurativo
     * @returns Observable<CommodityForCombinedSaleResponse>
     */
    getSupplies(fiscalCode: string, skuCombinedSales?: string): Observable<CommodityForCombinedSaleResponse> {
        return this.api
            .postAsync<BaseApiResponse<CommodityForCombinedSaleResponse>>(
                this.getApiMngApiUrl(ApiMngApi.GetQuoteOrderAssociation),
                {
                    fiscalCode,
                    skuCombinedSales,
                },
                undefined,
            )
            .pipe(
                tap((res) => {
                    if (res?.status !== StatusResponse.Success) {
                        throw new Error(res?.errorManagement?.errorDescription || 'Error calling api');
                    }
                }),
                map((response) => response?.response),
            );
    }

    /**
     * @description Effettua il controllo del codice coupon e lo applica al carrello
     * @param productConfigurationId id del carrello
     * @param couponCode codice coupon
     * @param segment
     * @param salesProcess
     * @returns Promise<BaseApiResponse>
     */
    checkCoupon(
        productConfigurationId: string,
        couponCode: string,
        segment: AptCustomerType,
        salesProcess: AptSalesProcess,
    ): Observable<BaseApiResponse> {
        return this.api
            .postAsync<BaseApiResponse>(this.getApiMngApiUrl(ApiMngApi.CheckCoupon), {
                productConfigurationId,
                couponCode,
                segment,
                salesProcess,
            })
            .pipe(
                map((response: any) => {
                    if (response.error) {
                        return { ...response.error };
                    } else {
                        return { ...response };
                    }
                }),
            );
    }

    /**
     * @description Effettua, passando per APIM, una query su APTTUS senza esporre in chiaro al frontend (SUP) la query stessa.
     * @param queryId Nome della query da richiamare (concordata con APIM)
     * @param parameters Parametri (le condizioni) da passare alla query
     */
    apttusQuery<T>(queryId: string, parameters: object): Observable<QueryResponseType<T>> {
        return this.api
            .postAsync<ApttusQueryResponse<T>>(this.getApiMngApiUrl(ApiMngApi.ApttusQuery), {
                queryId,
                parameters,
            })
            .pipe(
                tap((res) => {
                    if (res?.status !== StatusResponse.Success) {
                        throw new Error(res?.errorManagement?.errorDescription || 'Error calling apttusQuery api');
                    }
                }),
                map(({ response }) => response),
            );
    }

    /**
     * @description Applica la convenzione selezionata al carrello
     * @param productConfigurationId id del carrello
     * @param incentiveCode codice della convenzione selezionata
     * @returns Promise<BaseApiResponse>
     */
    async applyConvention(productConfigurationId: string, incentiveCode: string): Promise<BaseApexApiResponse> {
        return await this.api
            .postAsync<BaseApexApiResponse>(this.getApexApiUrl(ApexApi.ApplyConvention), {
                productConfigurationId,
                incentiveCode,
            })
            .pipe(
                map((response: any) => {
                    if (response.error) {
                        return { ...response.error };
                    } else {
                        return { ...response };
                    }
                }),
            )
            .toPromise();
    }

    /**
     * @description: invoca Apttus ed Apim per il check sulla vendibilità dei prodotti nel carrello in base al CAP/Istat inserito.
     * SOLO per i prodotti manutenzione si controlla se il cap inserito in fase di configurazione prodotto combacia con il cap recuperaro da EGON, in caso negativo OE viene bloccato
     * @param cartId: Id del carrello
     * @param address: Indirizzo di fornitura da verificare
     */
    checkFlowTypeCapIstat(
        cartId: string,
        address: Pick<Address, 'cap' | 'istatCodeMunicipality' | 'istatCodeProv'>,
    ): Observable<void> {
        return !address?.cap
            ? of(null) // skip if not cap
            : this.store.select(v2SelectFlowType).pipe(
                  take(1),
                  filter(
                      (flowType) =>
                          !flowTypeUtil(flowType).inMacroFlowTypes(
                              MacroFlowType.Voltura,
                              [MacroFlowType.SwitchIn, MacroFlowType.Administrative],
                              [MacroFlowType.VolturaSwitchIn, MacroFlowType.Administrative],
                          ),
                  ),
                  mergeMap(() =>
                      forkJoin([
                          this.checkCap(cartId, address?.cap),
                          // Effettuo la chiamata di controllo vendibilità in base al codice Istat solo se il flag isSwitchInE2EEnabled è abilitato
                          this.toggleService.isSwitchInE2EEnabled
                              ? this.checkIsAllowedSaleByIstat(addressToIstatCode(address))
                              : of(null),
                      ]),
                  ),
                  isEmpty(),
                  map(() => null),
              );
    }

    /**
     * @description: invoca Apttus per il check sulla vendibilità dei prodotti nel carrello in base al CAP inserito
     * @param cartId: Id del carrello
     * @param zipCode: cap di fornitura da verificare
     */
    checkCap(cartId: string, zipCode: string): Observable<void> {
        return this.api
            .postAsync<CheckCapResponse>(this.getApttusApiUrl(ApttusApi.CheckCap), {
                cartId: cartId,
                zipCode: zipCode,
            })
            .pipe(
                tap((res) => {
                    if (res?.Result !== EsitoResponse.OK) {
                        this.logger.error('errore nella chiamata CheckCAP', res?.Error || null);
                        throw new LogicError(
                            res?.Error || 'Si è verificato un errore nella chiamata CheckCAP',
                            res?.Result === EsitoResponse.KO,
                        );
                    }
                }),
                map(() => null),
            );
    }

    /**
     * @description: invoca Apim per il check sulla vendibilità dei prodotti nel carrello in base al codice Istat dell'indirizzo
     * @param codiceIstat: codiceIstat della fornitura da verificare
     */
    checkIsAllowedSaleByIstat(codiceIstat: string): Observable<void> {
        return this.store.select(v2SelectDistinctCommodities()).pipe(
            take(1),
            filter((products) => !!products?.length),
            mergeMap(() =>
                this.api.postAsync<BaseApiResponse<ProductsAffinityForComuneIstatResponse>>(
                    this.getApiMngApiUrl(ApiMngApi.ComuneAffinityByIstat),
                    { codiceIstat },
                ),
            ),
            tap((res) => {
                if (res?.status !== StatusResponse.Success || res?.response?.result !== 'N') {
                    throw new LogicError(
                        res?.errorManagement?.errorDescription ||
                            'Si è verificato un errore nella chiamata CheckIsAllowedSaleByIstat',
                        res?.response?.result === 'Y',
                    );
                }
            }),
            isEmpty(),
            map(() => null),
        );
    }

    /**
     * @description: invoca Apttus per calcolare la data di esecuzione del CP di un prodotto RATA FISSA
     * @param cartId: Id del carrello
     */
    calculateRataFissa(cartId: string): Observable<RataFissaResponse> {
        return this.api.postAsync<RataFissaResponse>(this.getApexApiUrl(ApexApi.CheckRataFissa), {
            cartId,
        });
    }

    /**
     * @description Invia il consumo di Autolettura a Neta per la validazione
     * @param consReq object con i parametri della request
     * @returns Promise<ConsumptionValidationResponse>
     */
    consumptionValidation(
        consReq: ConsumptionValidationRequest,
    ): Observable<BaseApiResponse<ConsumptionValidationResponse>> {
        return this.api.postAsync<BaseApiResponse<ConsumptionValidationResponse>>(
            this.getApiMngApiUrl(ApiMngApi.TransferSelfReading),
            consReq,
        );
    }

    /**
     * @description Recupera i dati richiesti GetInfoDistributore
     * @param InfoDistributoreRequest object con i parametri della request
     * @returns Observable<InfoDistributoreResponse>
     */
    getInfoDistributore(infoReq: InfoDistributoreRequest): Observable<InfoDistributoreResponse> {
        return this.api.postAsync<InfoDistributoreResponse>(
            this.getApiMngApiUrl(ApiMngApi.GetInfoDistributore),
            infoReq,
        );
    }

    /**
     * @description Recupera i costi associati al preventivo
     * @param RetrieveQuotationCostsRequest object con i parametri della request
     * @returns Observable<RetrieveQuotationCostsResponse>
     */
    retrieveQuotationCosts(body: RetrieveQuotationCostsRequest): Observable<RetrieveQuotationCostsResponse> {
        return this.api.postAsync<RetrieveQuotationCostsResponse>(
            this.getApiMngApiUrl(ApiMngApi.RetrieveQuotationCosts),
            body,
        );
    }

    /**
     * @description API sottomissione / upsert Preventivo
     * @param any object con i parametri della request
     * @returns Observable<anyS>
     * TODO: spostare in service di VT ???
     */
    workOrderManagement(body: Omit<Partial<WorkOrderAPIMRequest>, 'Sorgente'>): Observable<CreaPraticaResponse> {
        const rawBody: WorkOrderAPIMRawRequest = {
            creaPraticaRequest: {
                ...body,
                Sorgente: 'D365', //Passo D365 come richiesto per la risoluzione del bug 148926 - Da vedere se sia possibile fare un miglioramento lato API
            },
            InfoLog: {
                timestamp: null,
                transactionId: null,
                sourceSystem: 'D365', //Passo D365 come richiesto per la risoluzione del bug 148926 - Da vedere se sia possibile fare un miglioramento lato API
                sessionId: null,
                correlationId: null,
                userId: null,
            },
        };
        return this.api.postAsync<any>(this.getApiMngApiUrl(ApiMngApi.WorkOrderSubmission), rawBody).pipe(
            tap(
                (
                    res: BaseApiResponse<{
                        creaPraticaResponse: CreaPraticaResponseRaw;
                    }>,
                ) => {
                    if (!res?.response?.creaPraticaResponse || res?.response?.creaPraticaResponse?.Result === 'KO') {
                        throw new Error(res?.errorManagement?.errorDescription);
                    }
                },
            ),
            map((res) => res?.response?.creaPraticaResponse),
            map(({ CodicePraticaGTW, EventoFinale, DataInvioAttesa, ProssimoStatoCRM }) => ({
                codicePraticaGTW: CodicePraticaGTW,
                eventoFinale: EventoFinale,
                dataInvioAttesa: parseToDate(DataInvioAttesa),
                prossimoStatoCRM: ProssimoStatoCRM,
            })),
        );
    }

    /**
     * @description La funzionalità di SendSupplyAddress permette di modificare i dati relativi all'indirizzo di fornitura del cliente.
     * @param SupplyAddressRequest object con i parametri della request
     * @returns Observable<RetrieveQuotationCostsResponse>
     */
    sendSupplyAddress(body: SupplyAddressRequest): Observable<SupplyAddressResponse> {
        return this.api.postAsync<SupplyAddressResponse>(this.getApiMngApiUrl(ApiMngApi.SendSupplyAddress), body);
    }

    /**
     * Call an API to check if the PDF has already been linked to an insurance asset
     * @param pdf point of delivery (punto di fornitura)
     * @returns
     */
    checkInsurancePODPDR(pod_pdr: string, powerOrGas: AptCommodityType): Observable<void> {
        return this.api
            .postAsync<BaseApiResponse<CheckInsurancePodPdrResponse>>(
                this.getApiMngApiUrl(ApiMngApi.CheckInsurancePODPDR),
                {
                    pod_pdr,
                },
            )
            .pipe(
                tap((res) => {
                    if (
                        res?.status !== StatusResponse.Success ||
                        (res?.response?.items || []).find((i) => i.pod_pdr === pod_pdr)?.check !== OkKo.OK
                    ) {
                        throw new ServiceError(
                            'INSURANCE_POD_PDR_UNAVAILABLE',
                            res?.response?.errorMessage ||
                                res?.errorManagement?.errorDescription ||
                                this.translateSrv.instant('ERROR.EXTRACOMMODITY.INSURANCE_POD_PDR_UNAVAILABLE', {
                                    pod_pdr,
                                    podOrPdr: powerOrGas === AptCommodityType.Gas ? AptCodeType.pdr : AptCodeType.pod,
                                }),
                            'LOW',
                            { hideToastNotification: true },
                        );
                    }
                }),
                map(() => null),
            );
    }

    /**
     *
     * @description Va a controllare per polizze nel sistema NDS se ci sono assicurazioni già attive su quel CF.
     */
    checkInsuranceTaxCode(taxCode: string, sku: string, supplyAddress: Address, podPdr?: string): Observable<void> {
        return this.api
            .postAsync<BaseApiResponse<CheckInsuranceTaxCodeResponse>>(
                this.getApiMngApiUrl(ApiMngApi.CheckInsuranceTaxCode),
                {
                    FiscalCode_Piva: taxCode,
                    StockKeepingUnit: sku,
                    SupplyAddress: supplyAddress?.fullAddress,
                    PODPDR: podPdr,
                },
            )
            .pipe(
                tap((response) => {
                    if (response?.status !== StatusResponse.Success || response?.response?.Outcome === OkKo.KO) {
                        throw new ServiceError(
                            'INSURANCE_TAXCODE_UNAVAILABLE',
                            response?.errorManagement?.errorDescription ||
                                this.translateSrv.instant('ERROR.EXTRACOMMODITY.INSURANCE_TAXCODE_UNAVAILABLE'),
                            'LOW',
                            { hideToastNotification: true },
                        );
                    }
                }),
                map(() => null),
            );
    }

    /**
     * @description Chiamata al servizio di verifica della telelettura per le commodity GAS nei processi di Voltura
     * @returns Boolean che rappresenta se sia stata fatta la telelettura
     */
    getRemoteMeterCheck(codicePdr: string): Observable<boolean> {
        return this.api
            .postAsync<BaseApiResponse<RemoteMeterCheckResponse>>(this.getApiMngApiUrl(ApiMngApi.GestioneTeleletto), {
                codicePdr,
            })
            .pipe(
                tap((response) => {
                    if (response?.status !== StatusResponse.Success) {
                        this.logger.error(
                            'Controllo contatore teleletto',
                            response?.errorManagement?.errorDescription ||
                                this.translateSrv.instant('ERROR.GENERIC.API_MESSAGE'),
                            response,
                            true,
                        );
                    }
                }),
                map((response) => response?.response?.contatore?.flagTelelettura === YesNo.Yes),
            );
    }

    // TODO
    /**
     * @description Chiamata al servizio Nexi per aggiunta nuova carta di credito
     */
    addNewCreditCard(quoteId: string, operatorId: string): Observable<boolean> {
        return this.api
            .postAsync<BaseApiResponse>(this.getApiMngApiUrl(ApiMngApi.AddNewCreditCard), {
                quoteId,
                operatorId,
            })
            .pipe(
                tap((res) => {
                    if (res?.status !== StatusResponse.Success) {
                        this.logger.error(
                            'Aggiunta nuova carta di credito',
                            res?.errorManagement?.errorDescription ||
                                this.translateSrv.instant('ERROR.GENERIC.API_MESSAGE'),
                            res,
                            true,
                        );
                    }
                }),
                map(() => null),
            );
    }

    /**
     * @description Interroga apttus per verificare il valore del potenziale gas di default per il typeofusage specificato US 218300
     * @param lineItemId id del line item del prodotto
     * @param typeOfUsage tipo di utilizzo del prodotto gas
     * @returns Observable<DefaultGasActivationPotential>
     */
    checkActivationGasDefaultPotential(
        lineItemId: string,
        typeOfUsage: string,
    ): Observable<DefaultGasActivationPotential> {
        return this.api
            .postAsync<DefaultGasActivationPotential>(
                this.getApexApiUrl(ApexApi.CheckDefaultPotentialForGasActivation),
                { lineItemId: lineItemId, typeOfUsage: typeOfUsage },
                undefined,
            )
            .pipe(
                catchError(() => of(null)),

                map((x: DefaultGasActivationPotential) => x),
            );
    }

    /**
     * @description Interroga servizio RecuperaMatricolaStrumento esposto da Neta per il recupero informazioni misuratore
     * @param codicePdr pdr del line item del prodotto
     * @returns Observable<BaseApiResponse<RetrieveSerialNumeberResponse>>
     */
    getRetrieveSerialNumber(codicePdr: string): Observable<BaseApiResponse<RetrieveSerialNumeberResponse>> {
        return this.api
            .postAsync<BaseApiResponse<RetrieveSerialNumeberResponse>>(
                this.getApiMngApiUrl(ApiMngApi.RecuperoMatricolaStrumento),
                {
                    codicePod: codicePdr,
                },
            )
            .pipe(
                tap((res) => {
                    if (res?.status !== StatusResponse.Success) {
                        this.logger.error(
                            'Servizio recupero matricola Neta',
                            res?.errorManagement?.errorDescription ||
                                this.translateSrv.instant('ERROR.GENERIC.API_MESSAGE'),
                            res,
                            false,
                        );
                    }
                }),
            );
    }
}

export class LogicError extends Error {
    public isLogic: boolean = false;
    constructor(message: string, isLogic: boolean = false) {
        super(message);
        this.isLogic = isLogic;
    }
}

export type CheckType = 'pdr' | 'pod' | 'pdf' | 'asset';

export class CheckError extends Error {
    type: CheckType;

    constructor(type: CheckType, error: Error);
    constructor(type: CheckType, message: string);
    constructor(type: CheckType, errorOrMessage: Error | string) {
        super();
        this.type = type;
        if (typeof errorOrMessage === 'string') {
            this.message = errorOrMessage;
        } else if (errorOrMessage && typeof errorOrMessage === 'object') {
            this.message = errorOrMessage.message;
            this.stack = errorOrMessage.stack;
            this.name = errorOrMessage.name;
        }
    }
}
