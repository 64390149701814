import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EglState } from '../../../store/reducers';
import { selectAgentInfo } from '../../../store/selectors/user.selectors';
import { AgentInfo } from '../../models/user/agent';

@Injectable({
    providedIn: 'root',
})
export class AgentAuthorizationService {
    private _virtualAgentInfo: AgentInfo;
    constructor(private store: Store<EglState>) {
        this.store.select(selectAgentInfo).subscribe((va) => {
            this._virtualAgentInfo = va;
        });
    }

    get canCreateLeadFromThankYouPage(): boolean {
        return !!this._virtualAgentInfo?.CanCreateLeadFromThankYouPage;
    }
    get canCreateLeadFromHomePage(): boolean {
        return !!this._virtualAgentInfo?.CanCreateLeadFromHomePage;
    }
    get canSell(): boolean {
        return !!this._virtualAgentInfo?.CanSell;
    }
    get canAddRejectDeleteReasons(): boolean {
        return !!this._virtualAgentInfo?.CanAddRejectDeleteReasons;
    }
    get canSkipPlicoUpload(): boolean {
        return !!this._virtualAgentInfo?.CanSkipPlicoUpload;
    }
    get canSwitchInVip(): boolean {
        return !!this._virtualAgentInfo?.CanSwitchInVip;
    }
    get canRecoverSwitchIn(): boolean {
        return !!this._virtualAgentInfo?.CanRecoverSwitchIn;
    }
    get canDeliberateSwitchIn(): boolean {
        return !!this._virtualAgentInfo?.CanDeliberateSwitchIn;
    }
    get canTransferWithSwitchRecovery(): boolean {
        return !!this._virtualAgentInfo?.CanTransferWithSwitchRecovery;
    }
    get canTransferWithSwitchVip(): boolean {
        return !!this._virtualAgentInfo?.CanTransferWithSwitchVip;
    }
    get canDoRecoverActivation(): boolean {
        return !!this._virtualAgentInfo?.CanDoRecoverActivation;
    }
    get canDoVIPActivation(): boolean {
        return !!this._virtualAgentInfo?.CanDoVIPActivation;
    }
    get canDoManualProvisioningActivation(): boolean {
        return !!this._virtualAgentInfo?.CanDoManualProvisioningActivation;
    }
    get canDoAdministrativeModifyAssetType(): boolean {
        return !!this._virtualAgentInfo?.CanDoAdministrativeModifyAssetType;
    }
    get getCanDoCaringLight(): boolean {
        return !!this._virtualAgentInfo?.CanDoCaringLight;
    }
    get canViewUseGoodInStockPav(): boolean {
        return !!this._virtualAgentInfo?.Agency?.UseGoodInStockFlag;
    }
}
