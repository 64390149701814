import { AptSalesProcess } from '../../../common/enums/apttus/apt-sales-process';
import { D365ProfileCode } from '../../../common/enums/d365/d365-profile-code';
import { SearchQuoteFields } from './monitoraggio-quote.request';

export class CarrelliQuoteRequest {
    profileOperator: D365ProfileCode;
    domainName: string;
    dateFilter: 'signDate' | 'createDate' | '';
    dateFrom: string;
    dateTo: string;
    sortByField: string;
    sortByType: 'ASC' | 'DESC';
    generic: SearchQuoteFields;
    salesProcess: AptSalesProcess;
    constructor(profileOperator: D365ProfileCode, domainName: string) {
        this.profileOperator = profileOperator;
        this.domainName = domainName;
    }
}
