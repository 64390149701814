import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    ApiService,
    CacheService,
    ConfigurationService,
    MetadataService,
    PlatformConstants,
    PlatformService,
} from '@congacommerce/core';
import { includes, isEmpty, isNil } from 'lodash';
const _eglWindowConfig = (<any>window).sv || {};
@Injectable({
    providedIn: 'root',
})
export class EglPlatformService extends PlatformService {
    constructor(
        httpClient: HttpClient,
        _cache: CacheService,
        metadataService: MetadataService,
        config: ConfigurationService,
        apiService: ApiService,
    ) {
        super(httpClient, _cache, metadataService, config, apiService);
		debugger;		 
        let _a;
        let accessToken;
        if (!isEmpty(document.cookie) && includes(document.cookie, `${PlatformConstants.ACCESS_TOKEN}=`)) {
            accessToken =
                (_a = document.cookie
                    .split('; ')
                    .find((row) => row.startsWith(`${PlatformConstants.ACCESS_TOKEN}=`))) === null || _a === void 0
                    ? void 0
                    : _a.split('=')[1];
        }
        if (accessToken) {
            localStorage.setItem(PlatformConstants.ACCESS_TOKEN, accessToken);
        } else {
            // Generate search params for url fragment
            const fragmentParams = new URLSearchParams(window.location.hash.substring(1));
            // If access token is in visualforce page
            if (
                _eglWindowConfig &&
                _eglWindowConfig.accessToken &&
                _eglWindowConfig.accessToken !== 'NULL_SESSION_ID'
            ) {
                localStorage.setItem(PlatformConstants.ACCESS_TOKEN, _eglWindowConfig.accessToken);
                document.cookie = `${PlatformConstants.ACCESS_TOKEN}= ${_eglWindowConfig.accessToken};path=/;Secure;SameSite=Lax`;
            }
            // If access token is in url fragment
            else if (!isNil(fragmentParams.get(PlatformConstants.ACCESS_TOKEN))) {
                localStorage.setItem(
                    PlatformConstants.ACCESS_TOKEN,
                    fragmentParams.get(PlatformConstants.ACCESS_TOKEN),
                );
                document.cookie = `${PlatformConstants.ACCESS_TOKEN}= ${fragmentParams.get(PlatformConstants.ACCESS_TOKEN)};path=/;Secure;SameSite=Lax`;
            }
            this.apiService.onRefresh.subscribe((token) => this.setAccessToken(token));
        }
    }

    setAccessToken(accessToken) {
        if (!isNil(accessToken)) {
            this.connection.accessToken = accessToken;
            document.cookie = `${PlatformConstants.ACCESS_TOKEN}= ${accessToken};path=/;Secure;SameSite=Lax`;
            localStorage.setItem(PlatformConstants.ACCESS_TOKEN, accessToken);
        } else {
            delete this.connection.accessToken;
            document.cookie = '';
            localStorage.removeItem(PlatformConstants.ACCESS_TOKEN);
        }
    }
}
