import { Injectable } from '@angular/core';
import { CacheService } from '@congacommerce/core';
import { Cart, CartService } from '@congacommerce/ecommerce';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, share, take, tap } from 'rxjs/operators';
import { OrderEntryProvider } from '../../../../modules/common/order-entry/providers/order-entry-provider';
import {
    D365RetrieveUpsertCase,
    UpsertCaseReq,
} from '../../../../modules/switch-in/technical-changes/models/d365-upsert-case';
import { setV2CasaLavoroCartId, setV2IdPhoneCall } from '../../../../store/actions/order-entry-v2.actions';
import { setCombinedSale, setCommodityCartId, setQuoteId } from '../../../../store/actions/order-entry.actions';
import { MacroFlowType } from '../../../../store/models/flow-type';
import { EglState } from '../../../../store/reducers';
import { v2SelectAllProducts, v2SelectScontoCasaLavoro } from '../../../../store/selectors/order-entry-v2.selectors';
import { selectFlowType } from '../../../../store/selectors/order-entry.selectors';
import { selectAgentInfo, selectCurrentVirtualAgent, selectLead } from '../../../../store/selectors/user.selectors';
import { AptPaymentInstrument } from '../../../enums/apttus/apt-payment-instrument';
import { AptSalesProcess } from '../../../enums/apttus/apt-sales-process';
import { D365ChannelCode } from '../../../enums/d365/d365-channel-code';
import { salesProcessOrOperationTypeToFlowType } from '../../../functions/remap.functions';
import { flowTypeUtil, isScontoCasaLavoroActive } from '../../../functions/verifications.functions';
import { EglCartExtended } from '../../../models/apttus/tables/cart/egl-cart-extended';
import { CartToQuoteResponse, SaveQuoteProvider } from '../../../providers/save-quote.provider';
import { TelemetryMetricService } from '../../app/telemetry-metric.service';
import { D365Service } from '../../d365/d365.service';
import { FeatureToggleService } from '../../shared/feature-toggle.service';
import { LoadingService } from '../../shared/loading.service';
import { LocalStorageGenericService } from '../../shared/local-storage-generic.service';
import { LoggerService } from '../../shared/logger.service';
import { ApttusService } from '../apttus.service';
import { EglSalesupStateService } from '../tables/egl-salesup-state.service';
import { CartToQuoteRequestService } from './cart-to-quote-request.service';

@Injectable({
    providedIn: 'root',
})
export class CartToQuoteService {
    private pendingQuotes: { [key: string]: Observable<CartToQuoteResponse> } = {};
    constructor(
        private store: Store<EglState>,
        private quotePrv: SaveQuoteProvider,
        private apttusSrv: ApttusService,
        private orderEntryPrv: OrderEntryProvider,
        private logger: LoggerService,
        private telemetrySrv: TelemetryMetricService,
        private cacheSrv: CacheService,
        private salesupStateSrv: EglSalesupStateService,
        private cartToQuoteRequest: CartToQuoteRequestService,
        private localStorageSrv: LocalStorageGenericService,
        private d365Srv: D365Service,
        private toggleSrv: FeatureToggleService,
    ) {}

    public saveQuoteV2(): Observable<CartToQuoteResponse> {
        /**
         * ☠ ☠ ☠ ☠ GUIDELINE ☠ ☠ ☠ ☠
         *
         * Tutte le logiche di manipolazione carrello/entità varie di salesforce (es. aggiunta, modifica, rimozione prodotti tecnici)
         * devono essere fatte da BE (lato apim / salesforce).
         * Gli unici step triggerati da FE devono essere creazione quote (quote/fromCart),
         * finalizzazione quote (updateQuote), generazione plico (documentOT).
         *
         * Prima di implementare soluzioni fantasiose confrontarsi con F. Lombardi / M. Ricupero
         */
        const cartId = this.currentCartId;
        let caseID: string;
        this.manageGetPhoneCall();
        this.pendingQuotes[cartId] =
            this.pendingQuotes[cartId] ||
            this.salesupStateSrv
                .saveSupState('save-quote-v2')
                // Due pipe perché rxjs protesta per troppe operazioni in una sola pipe
                .pipe(
                    take(1),
                    // Costruisco la request per la fromCart
                    mergeMap(() => this.cartToQuoteRequest.getCartToQuoteReq()),
                    mergeMap((req) => {
                        //Dalla request mi salvo il caseID necessario per la chiusura del case, in questo caso solo per domiciliazione
                        if (
                            flowTypeUtil(
                                salesProcessOrOperationTypeToFlowType(req?.salesProcess as AptSalesProcess),
                            ).inMacroFlowTypes(MacroFlowType.Domiciliazione)
                        ) {
                            caseID = req?.billingAccount?.paymentTool?.caseID;
                        }
                        // Chiamo la fromCart
                        return (
                            this.quotePrv
                                .createQuoteFromCart(req)
                                // Chiamata a Nexi per aggiunta nuova carta di credito
                                .pipe(mergeMap((quote) => this.wrapperAddNewCreditCard(quote, req)))
                        );
                    }),
                    //chiamo il metodo che mi permette tramite D365 di chiudere il case aperto
                    mergeMap((quote) => (caseID ? this.closeD365Case$(quote, caseID) : of(quote))),
                    // Eseguo gli step successivi
                    mergeMap((quote) => this.apttusSrv.finalizeFlow$(quote)),
                    tap(({ Id: id }) => this.store.dispatch(setQuoteId({ id }))),
                )
                .pipe(
                    mergeMap((quote) => this.manageCombinedSales().pipe(map(() => quote))),
                    mergeMap((quote) => this.manageCasaLavoro().pipe(map(() => quote))),
                    tap((quote) => {
                        this.logger.info(`Cart ${cartId} has generated quote ${quote?.Id}`, quote);
                        this.cacheSrv.refresh(Cart);
                        CartService.deleteLocalCart();
                        this.localStorageSrv.productsZip = null;
                    }),
                    // Creazione nuovo carrello. Non viene resettato lo state in questo momento.
                    // Verrà fatto nel destroy della thxPage.
                    mergeMap((quote) =>
                        this.apttusSrv
                            .createNewCart(new EglCartExtended(), null, null, false, false, false)
                            .pipe(map(() => quote)),
                    ),
                    catchError((err) => {
                        this.logger.error(null, 'SaveQuote_V2 have an error', err, true, cartId);
                        delete this.pendingQuotes[cartId];
                        throw err;
                    }),
                    LoadingService.loaderOperator('Creazione quote in corso'),
                    this.telemetrySrv.rxTelemetry('save-quote-v2'),
                    share(),
                );

        return this.pendingQuotes[cartId];
    }

    private manageCombinedSales(): Observable<boolean> {
        return this.store.select(v2SelectAllProducts).pipe(
            take(1),
            map((products) => products.find((product) => product?.isCombinedSale)),
            tap((productCombined) => {
                // In caso di vendita abbinata commodity + assicurazione
                if (productCombined) {
                    this.logger.info(`'${productCombined?.name}' was configurated like 'Combined sale'`);
                    this.store.dispatch(setCommodityCartId({ commodityCartId: this.currentCartId }));
                    this.store.dispatch(setCombinedSale({ combinedSale: true }));
                }
            }),
            map((productCombined) => !!productCombined),
        );
    }

    private manageCasaLavoro() {
        return combineLatest([this.store.select(selectFlowType), this.store.select(v2SelectScontoCasaLavoro())]).pipe(
            take(1),
            map(
                ([flowType, isCl]) =>
                    isScontoCasaLavoroActive(flowType, this.toggleSrv.isScontoCasaLavoroEnabled) && !!isCl,
            ),
            tap((isCasaLavoro) => {
                if (isCasaLavoro) {
                    this.store.dispatch(setV2CasaLavoroCartId({ casaLavoroCartId: this.currentCartId }));
                }
            }),
            map(() => true),
        );
    }

    private wrapperAddNewCreditCard(quote: any, req: any): Observable<CartToQuoteResponse> {
        const obs$ =
            req.billingAccount?.paymentTool?.instrumentType === AptPaymentInstrument.CartaCredito &&
            !req.billingAccount?.paymentTool?.id
                ? this.orderEntryPrv.addNewCreditCard(quote.Id, req.channelAndAgency?.salesAgent)
                : of(null);

        return combineLatest([of(quote), obs$]).pipe(map(([quote]) => quote));
    }

    private get currentCartId(): string {
        return CartService.getCurrentCartId();
    }

    private closeD365Case$(quote: any, caseID: string): Observable<CartToQuoteResponse> {
        const upsertCase$ = this.d365Srv.retrieveUpsertCloseCase({ caseID }).pipe(
            take(1),
            mergeMap((retrieveUpsertCase: D365RetrieveUpsertCase) => {
                const upsertCaseReq: UpsertCaseReq = {
                    caseNumber: retrieveUpsertCase[0].ticketnumber,
                    processCode: '001',
                    customer: {
                        customerType: 'ACCOUNT',
                        customerCode: retrieveUpsertCase[0].accountnumber,
                    },
                    channel: retrieveUpsertCase[0].caseorigincode,
                    caseCharacteristic: retrieveUpsertCase[0].casecharacteristic,
                    caseLevel1Code: retrieveUpsertCase[0].caselevel1code,
                    caseLevel2Code: retrieveUpsertCase[0].caselevel2code,
                    caseLevel3Code: retrieveUpsertCase[0].caselevel3code,
                    status: '5',
                    caseResolutionReason: 'RISOLTO',
                    caseOrigin: retrieveUpsertCase[0].caseorigin,
                };
                return this.d365Srv.upsertCase(upsertCaseReq).pipe(
                    take(1),
                    map((res) => {
                        return res?.APIResult;
                    }),
                );
            }),
        );
        return combineLatest([of(quote), upsertCase$]).pipe(map(([quote]) => quote));
    }

    private manageGetPhoneCall() {
        return combineLatest([
            this.store.select(selectCurrentVirtualAgent),
            this.store.select(selectAgentInfo),
            this.store.select(selectLead),
        ])
            .pipe(take(1))
            .subscribe(([virtualAgent, agentInfo, leadInfo]) => {
                const channelCode = virtualAgent?.VirtualAgency?.Channel?.Code;
                const agent = agentInfo?.SystemUserId;
                const leadId = leadInfo?.leadid;
                if (channelCode === D365ChannelCode.TelesellingInbound && !leadId) {
                    let phoneCall: string;
                    this.d365Srv
                        .getPhoneCall(agent)
                        .pipe(take(1))
                        .subscribe((value) => {
                            phoneCall = value;
                            this.logger.info(`Retrieve for agent ${agent} - id phoneCall ${phoneCall}`);
                            this.store.dispatch(setV2IdPhoneCall({ idPhoneCall: phoneCall }));
                        });
                }
            });
    }
}
